import React from "react";
import MasterItem from "../../MasterItem/MasterItem";

const UsefulItem3 = () => {
  return (
    <MasterItem>
      <h1>
        چگونه باید مناسب ترین کاغذ را در تولید یک چاپ موفق و سودآور انتخاب نمود؟
      </h1>

      <p>
        یکی از مولفه های مهم در دست یابی به یک کار چاپی مطلوب، سطوح چاپی می
        باشد. ملزومات عمومی سطوح چاپی شامل: ظاهر فیزیکی مناسب، انتقال مناسب در
        سیستمهای چاپی، برهم کنش متعادل و متناسب با مرکب و در نهایت، کیفیت چاپ
        مطلوب می باشد. نظر به اینکه کاغذ، متداول ترین و عمومی ترین سطح چاپی
        قلمداد می گردد، شناخت آن در عملیات چاپ از اهمیت ویژه ای برخوردار است. در
        این راستا، ویژگیهای کاغذهای چاپ را نیز می توان در سه دسته ی اصلی خواص
        مکانیکی، خواص نوری و خواص ممانعتی در ارتباط با هریک از سیستمهای متداول
        چاپ ارزیابی نمود. در نهایت ، متناسب با هر نوع از عملیات چاپ و ملزومات
        کاغذ، آن سیستم چاپ می توان مناسب ترین کاغذ را در تولید یک چاپ موفق،
        سودآور و با کیفیت انتخاب نمود. در این مقاله، ملزومات اصلی کاغذهای متداول
        ترین شیوه های چاپ اعم از چاپ لترپرس، چاپ افست، چاپ روتوگراور، فلکسوگرافی
        و چاپ دیجیتال آورده شده است. همچنین ، انواع دسته جات کاغذهای چاپ و ویژگی
        های آنها نیز، طبقه بندی و برای استفاده ی کاربران تبیین گردیده است.
      </p>

      <p>
        هر عملیات چاپی ، نیازمند دو مؤلقه ی اساسی مرکب و کاغذ می باشد. هر فرآیند
        چاپی ، نیازمند مرکب با طراحی خاص مورد نیاز خود به منظور انتقال مناسب در
        حین عملیات چاپ به سطح بسته و عملیات خشک کنی مؤثر، می باشد. اگر چه، اجزا
        اصلی تشکیل دهنده ی همه مرکب های چاپ عبارتند از : رنگدانه ها، چسب و حلال
        با این وجود، مرکب های مورد نیاز متناسب با فرآیند چاپ مورد نظر، دارای
        ترکیبات و ویژگی های کاملا متفاوتی می باشد. از طرف دیگر، متداول ترین سطح
        زمینه ی چاپ کاغذ و مقوا مي باشد.
      </p>

      <p>
        آمار موجود نشان می دهد که بخش عظیمی از تولیدات صنعت کاغذسازی در حوزه ی
        چاپ مصرف می گردد. آن بخشی از کاغذ که مصرف صنایع بسته بندی می گردد نیز ،
        احتمالا مشمول عمليات چاپ می گردد. ملزومات عمومی سطوح چاپی شامل : ظاهر
        فیزیکی مناسب، انتقال مناسب در سیستم های چاپی، بر همکنش متعادل و متناسب
        با مرکب و در نهایت ، کیفیت چاپ مطلوب می باشد. از این رو، بر اساس ملزومات
        مذکور ویژگی های کاغذ در عملیات چاپ را می توان در سه دسته ی اصلی خواص
        مکانیکی خواص نوری و خواص ممانعتی تعریف نمود. از طرف دیگر، هر سیسستم چاپی
        متناسب با عملیات چاپ مرکب مورد استفاده، محیط چاپی و نیازمند سطوح مختلفی
        از پارامترهای مذکور می باشد. هدف این تحقیق، بررسی و مطالعه و در نهایت
        جمع بندی ویژگی های لازم کاغذهای مورد استفاده، در انواع مختلف سیستم های
        چاپ اعم از تکنیک های چاپ متداول و تکنیک های چاپ دیجیتال می باشد
      </p>

      <h1>تکنیک های چاپ متداول (سنتی)</h1>

      <p>
        همه ی فرآیند های چاپ متداول، دارای یک پلیت (صفحه) تامتغبیر و ثابتی هستند
        که در داخل سیستم چاپ یا خارج از آن ساخته می شود. فرآیندهای چاپ متداول را
        پس از تعبیه پلیت چاپ نام گذاری می کنند، از قدیمی ترین شیوه های به کار
        رفته در تولید کارهای چاپی ، می توان به چاپ لترپرس اشاره نمود که امروزه
        به عنوان یک تکنیک با اهمیت و کمتر از آن یاد می شود.. مهمترین شیوه های
        چاپ متداول عبارتند از: افست و فلکسوگرافی و گراور. در بررسی سیر تحول
        تکنیک های چاپ، می توان از فرایند چاپ لترپرس تا چاپ افست که امروزه در
        حدود ۵۰ درصد سهم بازار را به خود اختصاص داده نام برد. البته، امروزه با
        توسعه ی تکنیک های چاپ دیجیتال از سهم چاپ افست نیز کاسته می شود
      </p>

      <h1>کاغذهای مورد استفاده در چاپ لترپرس</h1>

      <p>
        در چاپ لترپرس و سطوح چاپی به صورت برجسته و جنس مواد آن از چوب، فلز یا
        مواد سنتزی سخت می باشد. در حین عملیات چاپ نیز، به صورت متداول این سطوح
        برجسته با ضربه و فشار، با سطح کاغذ تماس پیدا کرده و مرکب را به سطح کاغذ
        انتقال می دهند. بر مبنای کلیات ذکر شده، ویژگی های مورد نیاز کاغذ در این
        فرآیند چاپ را اینطور می توان طبقه بندی نمود: ۱- صافی و ترازمندی ۲- خاصیت
        کشسانی مطلوب ۳- تراکم پذیری ۴- حجم ویژه با بالک مناسب ۵- مرکب پذیری
        مطلوب میزان صافی کاغذ، معرق همان بی نظمی های کوچکی است که با چشم غیر
        مسطح قابل مشاهده نمی باشند. ترازمندی نیز، به بی نظمی هایی اشاره دارد که
        با چشم غیر مسلح قابل تشخیص می باشند. کاغذهای چاپ لترپرس در تولید یک کار
        چاپی مطلوب باید کشسان، تراکم پذیر و بالکی بوده باشند. ویژگهای عنوان شده،
        تعیین کننده ی ویژگی ضربه گیری و برگشت پذیر چاپ می باشد که نشانگر خاصیت
        تراکم پذیر یا جهندگی کاغذ است. این خاصیت ، پس از هر فشار و ضربه در
        عملیات چاپ به ترازمندی و صافی سطح کاغذ کمک می کند. یکی دیگر از شرایط
        لازم کاغذهای این فرآیند چاپ مرکب پذیری مناسب آن در قالب توانایی سطح
        کاغذ، در انتقال یکنواخت و گاهی مرکب از صفحه ی چاپ و جذب آن می باشد. از
        آنجا که در چاپ لترپرس کاغذ به – وسیله ی لایه نسبتا ضخیمی از مرکب چاپ می
        شود یک چاپ با کیفیت ، نیازمند انتقال مرکب بدون بخش شدن تصویر چاپ می باشد
        و در نهایت از آنجا که در چاپ لترپرس در تماس با محیط های آبی قرار نمی
        گیرند، می توان حتی با استفاده از کاغذهای کم آهار بدون اندود سطحی، همچون
        کاغذهای تحریر به چاپ خوبی دست یافت.
      </p>

      <h1>کاغذهای مورد استفاده در چاپ افست</h1>

      <p>
        ملزومات کاغذهای چاپ افست اعم از چاپ افست ورقی یا چاپ افست نواری، چاپ
        افست با شیوه های متداول و یا مدرن، كاغذ در مواجهه با تنش های مکانیکی
        (مانند تنش کششی ) حین عبور از پلیت‌های سیلندر یک چاپ، سیستم خنک کننده یا
        خشک کن و در تماس با رطوبت یا مرکب های چسبناک قرار می گیرند، از این رو
        کاغذهای مورد استفاده در این سیستم چاپ، نیازمند یکسری ویژگی های فیزیکی،
        مکانیکی و ممانعتی خاص خود در دستیابی به یک چاپ با راندمان بالا و کیفیت
        مطلوب می باشند
      </p>

      <p>
        ۱- Print cushion ویژگی کلی کاغذ مورد نیاز در این فرایند را می توان بدین
        ترتیب طبقه بندی نمود : مقاومت سطح و قدرت پیوند دروني بالا ٢- پایداری در
        برابر محلول مرطوب کنندگی ماشین در قالب حفظ مقاوم های مکانیکی، خنثی بودن
        از نظر شیمیایی و پایداری ابعادی ٣- تمیزی کاغذ بر اساس روند متداول این
        چاپ، کاغذ پس از الحاق مركب تحت فشار بسیار بالا، در شکاف بین استوانه ی
        فشار و سطح پوشش لاستیکی ماشین قرار می گیرد. به هنگام جدا شدن استوانه ی
        لاستیکی از سطح کاغذ در ادامه ی عملیات چاپ، سطح کاغذ با نیروی قابل ملاحظه
        ای کشیده می شود. فشار واقعی اعمال شده به عواملی چون چسبناکی مرکب، سرعت
        ماشین چاپ زاویه ی جدا شدن کاغذ از پوشش لاستیکی و … بستگی دارد. در مواجهه
        با این تنش ، سطحی کشش کاغذ باید مقاومت سطح و درونی بالا داشته باشید تا
        در برابر کنده شدگی سطحی الیاف کاغذ و یا مواد اندود سطح کاغذ و در نهایت
        تخریب ورقه ی چاپ مقاومت کند. از طرفی دیگر ، مقاومت های متداول این کاغذ
        باید پس از انتقال رطوبت از پوشش لاستیکی افست به کاغذ نیز حفظ شود، چرا که
        اگر رطوبت الحاق شده به کاغذ در طی فرآیند چاپ موجب نرم شدن لیگنین یا افت
        سطح پیوند نسبی بین الیاف در کاغذ (به عنوان مهم ترین عامل تعیین کننده ی
        مقاومت های مکانیکی کاغذ) شود، می تواند منجر به کنده شدن فیبر یا کندشدگی
        اندود و در نهایت ، متلاشی شدن سطح کاغذ شود. بنابراین کاغذهای مورد
        استفاده در فرآیندهای چاپ افست ، بهتر است که دارای آهار محکمی باشند تا از
        رطوبت گیری بیش از حد ورقه ی کاغذ جلوگیری شود. بحث مقاومت کاغذ در برابر
        رطوبت از یک دیدگاه کاربردی دیگر نیز، حایز اهمیت است. ترکیبات موجود در
        کاغذ اعم از اجزاء لیفی، مواد پرکننده ی معدنی و … نباید در تماس با آب و
        طی آبشویی، به ماشین چاپ منتقل شده و توازن شیمیایی پوشش لاستیکی و پلیت
        چاپ را مختل کند، در ارتباط با اثر رطوبت، أخرين مسئله، پایداری ابعاد کاغذ
        چاپ افست تحت تأثیر رطوبته عملیات چاپ افست، چرا که در حالت معمول، كاغذ
        تحت تأثیر رطوبت آب جذب کرده و یا جای دهی آن در بین الیاف به واسطه ی
        پیوندهای هیدروژنی جدید موجب واکشیدگی بزرگ شدن ابعاد خود می گردد که این
        خود، یک عامل منفی در خصول یک چاپ چند رنگ شارپ و تمیز می باشد. غبار ناشی
        از عملیات برش، قطع یا لبه بری کاغذ می تواند، مشکلاتی را طی عملیات چاپ
        افست ایجاد کند. بدین ترتیب که غبار ایجاد شده به تدریج، در تداوم عملیات
        چاپ وارد مرکب شده و ماهیت سیالیت آن را تغییر می دهد و موجب افت کیفیت
        تصویر چاپی در مراحل بعد می شود که به این مشکل ، پیلینگ با توده شدگي گفته
        می شود . در این حالت، مرکب براثر نامناسب بودن ترکیب آن به جای انتقال
        آسان به ورقه، روی پوشش لاستیکی با بر روی لوح غلتک ها انباشته می شود. در
        یک نگاه کلی ، ویژگی های ذکر شده در بالا هم برای کاغذهای پوشش دار و هم
        برای کاغذهای بدون پوشش صدق می کند، اما رفتار متفاوتی در مقابل عوامل
        مذکور از خود ارائه می کنند. مشکلات پایداری ابعادی در چاپ نواری نسبت به
        چاپ ورقي ، شدت کمتری دارند، زیرا در تمام ماشین، کشش بر روی نوار کاغذ حفظ
        می شود. علاوه بر این، ورقه، در یک بار گذر از ماشین به صورت دو رو چاپ می
        شود و بدین طریق، مشکلات چاپ نامطلوب و ثبت دقیق یک تصویر که در اثر
        تغییرات ابعادی در فاصله ی بین گذرها رخ می دهند حذف می شود.
      </p>

      <h1>کاغذهای مورد استفاده در چاپ فلکسوگرافی</h1>

      <p>
        فلکسوگرافی، یک چاپ برجسته تلقی می شود اما از آنجا که سطوح برجسته ی چاپی
        از جنس یک لاستیک انعطاف پذیر است، با چاپ لترپرس متفاوت است، از طرف دیگر
        از آنجا که سیلندر فشار این فرآیند چاپ ، تنها یک فشار جزئی را به منظور
        ایجاد تماس بین کاغذ و سطح تماس لاستیکی ایجاد می کند با چاپ افست که در آن
        سیلندر فشار، تنش زیادی را ایجاد می کند فرق میکند. فلکسوگرافی نوعی چاپ
        برجسته از جنس یک لاستیک انعطاف پذیر است. قابلیت چاپ، سطوح چاپی سخت و
        برتری همچون مقوای بسته بندی را دارا می باشد. همچنین، در این فرآیند چاپ
        به جای مرکب های غليظ و چسبناک از مرکب های روان تر و فرار تر استفاده می
        شود. از طرفی، چون سیلندر فشار این دستگاه تنها یک فشار جزئی را با هدف
        ایجاد تماس ملایم کاغذ و سطوح برجسته ی پلیت چاپ ایجاد می کند، با چاپ افست
        که این فشار بسیار زیاد است فرق می کند بر اساس مطالب ذکر شده، به راحتی می
        توان دریافت که ویژگی های کاغذهای مورد استفاده در این فرآیند چاپ با سایر
        فرآیندها متفاوت می باشد. اساسا دليل ابداع روش فلکسوگرافی در چاپ برجسته
        کاستن از تغییر شکل کاغذ زیر فشار نورد چاپ ، در روش لترپرس بوده است در یک
        نگاه کلی، خواص مورد نیاز کاغذهای چاپ فلکسوگرافی همانند خواص کاغذهای چاپ
        لترپرس می باشد. اما ، براساس تفاوت های فرآیندی ذکر شده بدیهی است که در
        فلکسوگرافی، شکل گیری کاغذ و کیفیت سطح کمتری ، مورد نیاز است و حتی با این
        فرآیند می توان بر روی کاغذهای خشن و زیر به چاپ مطلوبی دست یافت، زیرا،
        تصاویر نرم و برجسته ی لوح فلکسوگرافی دارای خاصیت کشسانی و انعطاف پذیر
        بوده و از طریق مقداری فشار، می توان مرکب را به سطوح غير صاف نیز برساند.
      </p>

      <h1>کاغذهای مورد استفاده در چاپ روتوگراور</h1>

      <p>
        از آنجا که در این فرآیند چاپ، عناصر چاپی پایین تر از سطح لوح قرار می
        گیرد، یک چاپ اینتاگليو تلقی می گردد. در این فرایند، هر کی به شیوه های
        مختلف همچون اسپری به سیلندر چاپ تزریق می گردد. در این سیستم چاپ، کاغذ به
        وسیله ی یک سیلندر فشاری یا سیلندر پشتیبان و در مقابل سیلندر لوح چاپ با
        زینک نگه داشته می شود، مرکب طی پدیده ی مویینگی ،از سلولهای روی سیلندر
        لوح به سطح ورقه، کشیده شده و چاپ صورت می گیرد. این روش چاپ ، به خاطر
        کیفیت بالای تکثیر رنگ بر روی ارزان ترین درجات کاغذ مثل کاغذ های تحریر یا
        کاغذ گرانوود اندود دهی شده مورد توجه می باشد، به خاطر مکانیسم خاص این
        عملیات چاپ، کاغذ های مورد استفاده در این عملیات چاپ . از ویژگی های خاصی
        برخوردارند. صافی و تراکم پذیری خوب از مهمترین شرایط کاغذهای گراور است.
      </p>

      <h1>کاغذهای مورد استفاده در چاپ دیجیتال </h1>

      <p>
        چاپ الکترونیک با چاپ دیجیتال، اشاره به فرایندهایی از چاپ دارد که کل
        جریان کاری از نسخه ی دستی تا محصول چاپی نهایی ، به وسیله ی کامپیوتر
        هدایت می شود. چاپ دیجیتال همچنین ، نیازمند سیستمی از چاپ است که تصویر
        تولید شده را بتوان از یک صفحه تا صفحه ی دیگر تغییر داد. این امر، در چاپ
        الکتروفوتوگرافی با سیلندرهای چاپ مختلف و در چاپ جوهر افشان با نرم افزار
        مربوطه محقق می گردد . فرایند های چاپ دیجیتال اعم از لیزر و جوهر افشان،
        متناسب با مکانیسم چاپ خود، نیازمند ویژگی های خاصی هستند. مثلا در چاپ
        لیزر به خاطر حرارت اعمال شده به کاغذ بعد از اعمال تونر نیاز به کاغذهایی
        با ویژگی دوام حرارتی بالا در مقابل تغییر ابعاد، تغییر رنگ و تقبير مقاومت
        ها می باشد(همان). در ارتباط با چاپ جوهر افشان ،اگر، هدف چاپ متون مختلف
        با تصاویر با کیفیت متوسط می باشد، می توان از کاغذهای معمول اداري بدون
        اندود سطحی استفاده نمود. این نوع کاغذها را می توان به صورت چند منظوره
        برای چاپ های افست و چاپ لیزری نیز استفاده نمود. در چاپ جوهر افشان، به
        منظور تولید تصاویر با کیفیت بالا، حتما بایستی از کاغذهایی اندود سطح ویژه
        استفاده نمود. این اندود سطحی می تواند شامل چندین لایه ی مخصوص مانند یک
        لایه که تثبیت کننده بی رنگ و یک لایه ی جاذب مرکب باشد. لایه ی تثبیت
        کننده بی رنگ و در بردارنده ی مواد شیمیایی خاصی است که با رنگدانه ها
        واکنش داده و آنها را در سطح، به دام می اندازد. این پدیده موجب بهبود
        تراکم و غلظت رنگ و اشباع آن در حصول یک چاپ عالی می گردد. ترکیبات شیمیایی
        این لایه عمدتا از پلی دادمک و پلی وینیل پیرولیدون تشکیل می گردد. لایه ی
        جاذب مرکب ، ممکن است متشکل از ذرات متخلخلی باشد که دارای سطح ویژه ی
        بالایی می باشند. از آن جمله می توان به سیلیکا ژل یا پلیمرهای متورم
        شونده، همچون ژلاتین اشاره نمود کاغذهای جوهر افشان برای چاپ براق تصاویر
        اغلب، دارای یک لایه ی متورم شونده بوده و یک چاپ بسیار عالی را، نتیجه می
        دهند، لیکن ، زمان خشک شدن تصاویر چاپی نیز طولانی است
      </p>

      <h1>بررسی کاغذهای چاپ دیجیتال تونری</h1>

      <p>
        یافته ها نشان می دهد که مهمترین و حیاتی ترین ویژگی های در کاغذهای چاپ
        دیجیتال (تونری) ثبات ابعادی، یکنواختی تولید کاغذها، و کیفیت چسبندگی تونر
        به سطح است و این سه ویژگی به میزان زیادی بر روی قابلیت عبور کاغذ و سرعت
        و کیفیت دستگاه های چاپ و فرآیندهای تکمیلی اثر گذارند. با این حال برخی
        ویژگی های دیگر مثل تمیزی سطح کاغذ و میزان انتقال پرکننده ها و غبار داخل
        کاغذ به سطح نوردهای ذوب کننده تونر و میزان سفتی و میزان رطوبت بر قابلیت
        چاپ دستگاه ها، بر میزان تقاضای این کاغذها موثر هستند. همچنین ویژگی هایی
        مثل میزان ماتی، صافی سطح، نوع و کیفیت پرداخت سطح، یکنواختی براقیت سطح
        کاغذ بر کیفیت چاپ موثر هستند. همچنین مهمترین ویژگی هایی که در فرآیند
        انتقال و تثبیت تونر نقش مهمی دارند خواص مقاومتی سطح، انرژی سطحی، و میزان
        رطوبت کاغذ هستند که در دانسیته و یکنواختی انتقال تونر روی کاغذ نقش
        دارند. زیرا اگر انرژی مرحله ذوب بتواند به طور کاملا یکنواخت روی سطح کاغذ
        منتشر شود، تغییرات و غیریکنواختی ها در دانسیته تونر منتقل شده در سطح
        کاغذ بسیار کم خواهد بود. از ویژگی های مهم دیگر در کاغذهای تونری می توان
        به قابلیت استفاده از آن در سیستم های چاپی شرکت های مختلف تولیدکننده
        دستگاه ها و قابلیت انطباق با رنگهای پنتون اشاره کرد. البته به طور کلی
        کاغذ به این شکل تولید نمی شود که بتواند تمامی ویژگی های مورد نیاز کاغذ
        در چاپ تونری را در حد عالی داشته باشد. بلکه متناسب با ترجیح و نیاز چاپی
        مصرف کننده در یک فرآیند خاص، کاغذهای مختلف با ویژگی های مختلفی تولید می
        شود. به همین دلیل هم گاهی بسیاری شرکت های تولیدکننده دستگاه های پرینتر
        کاغذ و مرکب متناسب با دستگاه خودشان را به مشتریان توصیه می کنند. از
        آنجاکه که تونر با استفاده از تخلیه بار الکترونیکی روی سطح کاغذ می نشیند،
        طراحی سیستم های چاپ و هدایت الکترونیکی سطح چاپ شونده باید حد و اندازه
        معینی داشته باشد. میزان رطوبت سطح چاپ شونده بسیار حیاتی است زیرا میزان
        رطوبت به شدت روی ویژگی های دی الکتریکی کاغذ تاثیرگذار است. میزان رطوبت
        کاغذ باید در حد کافی و در عین حال تا حد ممکن کم باشد تا بتواند ویژگی های
        دی الکتریکی و قابلیت نگهداری شارژ الکتریکی کافی برای انتفال تونر را
        داشته باشد. البته رطوبت بیش از حد پائین در این نوع کاغذها هم باعث بوجود
        آمدن الکتریسیته ساکن و اشکالاتی در انتقال تونر و چاپ میشود. از طرف دیگر
        رطوبت بیش از حد در بافت کاغذ باعث می شود تا کاغذ نتواند میزان کافی از
        شارژ الکتریکی را در خود نگه دارد و در نتیجه انتقال ضعیف تونر را خواهیم
        داشت. بنابراین دستگاه های الکتروگرافی باید به شکلی طراحی شوند که بتوانند
        هدایت الکتریکی کاغذ را در حد مطلوب در کل فرآیند چاپ حفظ کنند. البته
        علاوه بر این عوامل دیگری مثل ماهیت خمیر کاغذ مورد استفاده، جهت گیری
        الیاف در بافت کاغذ، و نوع و ویژگی پرکننده های موجود در بافت کاغذ هم در
        ویژگی های هدایت الکتریکی تاثیرگذار است. ماندگاری تونر بر روی سطح کاغذ هم
        حائز اهمیت است. اتصال تونر با سطح کاغذ از طریق ذوب شدن آن تحت تاثیر فشار
        و گرما اتفاق می افتد. در طی فرآیند فیوزینگ (ذوب شدن) تونر پلیمری آنقدر
        گرم می شود تا به دمای انتقال شیشه برسد. در این دما تونر جامد به صورت
        مایع در می آید و ذرات جدا از هم شروع به یکی شدن (پلیمریزاسیون) می کنند و
        به سرعت ویسکوزیته تونر شروع به کاهش می کند. در طی این مایع شدن تونر به
        داخل بافت کاغذ نفوذ می کند و با الیاف کاغذ پیوند برقرار می کند.مقاومت
        این اتصالات به شدت تحث تاثیر نفوذ پذیری کاغذ است. این نفوذ پذیری تحت
        تاثیر شکل، اندازه، و میزان فضاهای خالی در بافت کاغذ و شرایط فیزیکی ماده
        جذب شونده است. ویژگی های فیزیکی بستر چاپ شونده بسیار در شرایط چاپی چاپ
        الکتروگرافی تاثیر گذار است. ثبات ابعادی سطح چاپ شونده همانطور که می
        دانید به طور تنگاتنگی مرتبط با تغییرات میزان رطوبت بافت کاغذ است که می
        توند مربوط به فرآیند ذوب شدن (فیوزینگ) باشد. شرایط پرداخت سطحی کاغذ هم
        بسیار مهم است. در اینجا وقتی که جوهر به سطح نفوذمی کند، تونرها باید
        بوسیله شارژ الکترواستاتیکی و با کمک مکانیسم های فیزیکی جذب بافت کاغذ
        شود. جهت الیاف هم یکی دیگر از فاکتورهای حیاتی کاغذهای چاپ دیجیتال است که
        با تاثیر بر روی سفتی بر روی قابلیت انتقال کاغذ تاثیرگذار است. وقتی جهت
        الیاف موازی با جهت حرکت کاغذ در دستگاه است، سفتی بیشتری دارد و مقاومت
        بیشتری در مقابل خمش و گیر کردن از خود نشان می دهد. در بسیاری موارد جهت
        الیاف در کاربرد نهایی و فرآیندهای تکمیلی تولید محصول تاثیرگذار است و اگر
        به این دلیل ناچار باشیم از کاغذ با جهت الیاف ناصحیح استفاده کنیم ممکن
        است ناچار شویم با در نظر گرفتن میزان ضایعات بیشتر این مشکل را جبران
        کنیم. به دلیل وجود مرحله فرآیند تثبیت حرارتی کاغذها میزان رطوبت پائینتری
        در چاپ تونری نسبت به فرآیندهای دیگر مثل افست دارند. این مسئله می تواند
        منجر به افزایش مشکلات مربوط الکتریسیته ساکن و لول شدن و تاب برداشتن در
        کاغذ شود. روشنی، سفیدی، و ته رنگ هم در انتخاب کاغذهای دیجیتال تونری باید
        در نظر گرفته شود. ته رنگ یا سایه روشن یا شید ( shade ) ویژگی ای است که
        به شدت روی درک بصری ما تاثیر می گذارد. شید در حقیقت همان تغییرات ظریف
        رنگی در کاغذ در محدوده نور مرئی است. اگر قصد رت سیاه و سفید یا تک رنگ را
        دارید (مثل کاغذهای کپی) می توانید خریدتان را براساس یکی از عوامل روشنی
        یا سفیدی انجام دهید. از نظر فنی ته رنگ نقش بسیار مهمی در تعیین و تعریف
        سفیدی یک کاغذ دارد. شید خصوصا در چاپ های رنگی میتواند مستقیما در دید و
        احساس رنگی صحیح در تصاویر چاپ شده تاثیر می گذارد.فعل و انفعال بین تونر
        یا جوهر چاپی و شید کاغذ تعیین می کند که تصاویر چاپ شده تا چه اندازه به
        رنگ طبیعی و واقعی نزدیک است. می بینیم که هر دوی سیستم های چاپ دیجیتال و
        افست تا حدودی با چالش های تقریبا مشابهی پیرامون کاغذ درگیر هستند. در
        درجه اول سطح چاپ شونده باید بتواند به طور صحیحی از دستگاه چاپ عبور
        کند(قابلیت عبور مناسب) . در درجه دوم سطح چاپ شونده باید بتواند مرکب یا
        تونر را به طرز مناسب و مطابق با اصول فرآیند و بدون بروز اثرات نامطلوب
        مربوطه بپذیرد و جذب کند. سوم اینکه این فرآیند نهایتا باید به یک کیفیت
        مطلوب منتهی شود. و نهایتا اینکه سطح چاپ شده باید قادر باشد که تنشها و
        مشکلاتی که در طی فرآیند مصرف به آن وارد می شود تحمل کند. از آنجائیکه
        رقابت بین فرآیندهای چاپی افست و دیجیتال برای تصاحب بازار مصرف در حال اوج
        گرفتن است به نظر می رسد درآینده نیاز به تولید کاغذهایی برای چاپ تونری
        داشته باشیم که هماهنگ و سازگار با پیشرفت های آینده دستگاه ها باشند. از
        جمله این موارد می توان به چند مورد زیر اشاره کرد : کیفیت های بالای چاپ
        را تامین و تضمین کنند. در سرعت های بالای چاپ قابلیت عبور و کیفیت مناسب
        داشته باشند. تنوع بیشتری در سایز، گراماژ، و ضخامت داشته باشند. توانایی
        چاپ بر روی کاغذ در سیستم های مختلف چاپی (دستگاه های تولیدی شرکت های
        مختلف).
      </p>

      <h1>طبقه بندی کاغذهای چاپ</h1>

      <p>
        بهترین طبقه بندی کاغذهای چاپ و بر اساس زمینه ی کاربرد آن می باشد. از
        آنجا که در بازار، گستره ی وسیعی از انواع کاغذ با درجات کیفی و قیمت های
        متفاوت یافت می شود، انتخاب نوع مناسب کاغذ برای یک کار چاپی از اهمیت
        بالایی برخوردار است. برای مثال، كاغذهای مورد نیاز روزنامه و مجله،
        نیازمند مشخصه های کیفی پایین تری از کاغذهای به کار رفته در چاپ کتاب های
        منحصر به فرد گران قیمت دارای تصاویر خاص مانند: کتاب های هنر، باغبانی،
        آشپزی و غیره می باشند. مشخصه های کیفی مهم کاغذ در انواع درجات کاربردی
        کاغذ متفاوت می باشد، اما در تمام این انواع کاغذهای چاپ، ویژگی هایی چون
        نرمی، درجه که روشنی، حجیمی و چاپ پذیری حائز اهمیت می باشد عبارت چاپ
        پذیری اشاره به قابلیت کاغذ در جذب مناسب مرکب، سادگی خشک شدن آن و تولید
        پک چاپ مطلوب در کنار تحمل تنش های وارد به كاغذ در عملیات چاپ اشاره دارد.
        کاغذ روزنامه کاغذ روزنامه ، از خمیر کاغذ بازیافتی مرکب زدایی شده و یا یک
        خمیر مکانیکی (مثل خمیر کاغذ گراندوود با حرارتی مکانیکی با اختلالی از این
        دو نوع خمير تولید می شود. گراماژهای متداول کاغذهای روزنامه عبارتند از:
        ۴۲و ۴۵ و ۴۹ گرم بر متر مربع. از مهم ترین ویژگی های کاغذهای روزنامه
        عبارتند : مقاومت : کاغذهای روزنامه اغلب دارای گراماژ پایین بوده و تحت
        چاپ افست قرار می گیرند تا هزینه ی تمام شده محصول پایین باشد. قطع و پارگی
        نوار کاغذ طی عملیات چاپ که یک پارامتر منفی و هزینه بر می باشد از مقاومت
        کششی پایین نوار کاغذ نشات می گیرد که باید لحاظ نمود. به منظور حداقل سازی
        ایجاد گرد و غبار در طی عملیات چاپ، کاغذ مورد استفاده باید دارای مقاومت
        سطحی بالایی باشد . ماتی: از آنجا که کاغذ روزنامه دارای گرامار پایینی است
        ، باید در انتخاب کاغذ دقت نمود تا از ماتی خوبی برخوردار باشد تا از ایجاد
        پشت نمایی بعد از عملیات چاپ ممانعت گردد. زبری سطح کاغذ: کاغذ روزنامه
        باید از یک زبری متوسط برخوردار باشد. زیرا، زبری زياد مانع ایجاد یک چاپ
        با کیفیت براق می شود. از طرفی، زبری کم موجب لکه دار شدن تصاویر چاپی در
        برخورد صفحات روزنامه با هم با در تماس با دست مصرف کننده میشود. کاغذ مجله
        این دسته از کاغذها در تولید و چاپ کاتالوگ و مجلات، ژورنال ها و غیره به
        کار می روند. چندین نوع مختلف کاغذ برای این نوع کاربردهای مورد استفاده
        قرار می گیرند. طی دو نوع از متداول ترین آنها عبارتند از: کاغذ فوق غلتک
        زنی شده و کاغذ اندود شده ی سبک و کاغذ SC ، از خمیر کاغذ حاوی مقادیر زياد
        خمیرهای مکانیکی با خمیر مرکب زدایی شده تقویت شده با خمیر سولفات و با
        مقادیر بالای مواد پر کننده (مانند رس) تشکیل شده است. این کاغذ، به شدت
        غلتک زنی می گردد تا به سطوح بالایی از صافی و براقیت سطح برسد. خمیر LWC
        عموما، ترکیبی از خمیرهای مانیکی و شیمیایی با نسبت برابر می باشد. این
        کاغذ، با اندود سبکی (حدود ۸ تا ۱۰ گرم بر متر مربع) پوشش دهی شده و در
        نهایت غلتک زنی می گردد. گراماژ کاغذ های SC و LWC در گسترهی ۴۹ تا ۶۰ گرم
        پر متر مربع می باشد. متداول ترین گراماژ این نوع از کاغذها در حوزه ی
        کاربردی، ۵۲ و ۵۶ گرم بر متر مربع، برای کاغذ SCو مقادیر بالاتر آن برای
        کاغذهای LWC می باشد . مهم ترین ویژگی های کاربردی این نوع از کاغذها، در
        قالب زیر ارائه گردیده است: مقاومت: کاغذهای مجلات عمدتا در فرآیند چاپ
        افست یا روتوگراور ورقی تحت عملیات قرار می گیرد لذا، بایستی تا حد ممکن از
        پارگی نوار کاغذ در حین عملیات چاپ ممانعت نمود. مقاومت سطحی کاغذ: به دلیل
        ضخامت بالای مرکب مورد استفاده در فرآیند چاپ افست، این نوع کاغذها باید از
        مقاومت سطحی بالایی برخوردار بوده تا از کنده شدن سطحی الياف ممانعت گردد.
        ماتی: چون گراماژ و ضخامت این کاغذها پایین است به منظور جلوگیری از پشت
        نمایی چاپ ها، این نوع از کاغذها باید از ماتی بالایی برخوردار باشند. زبری
        سطح و براقیت کاغذ: صافی سطح، یک فاکتور مهم برای این نوع از کاغذها در
        راستای بهبود براقیت و کیفیت کار چاپی و ممانعت از ایجاد لکه های چاپی به
        ویژه در چاپ روتوگراور می باشد. به دلیل غلتک زنی شدید این دسته از کاغذها،
        حجم ویژه و زبری آن ها به شکل مطلوبی کاهش می یاید. کاغذ کتاب کاغذ کتاب
        عموما در تهیه ی کتاب های متون متداول کتاب های جیبی، رمان و غیره مورد
        استفاده قرار می گیرد. این کاغذها عموما، از خمیرهای مکانیکی با اختلاط با
        مقادیر اندکی از خمیرهای شیمیایی به عنوان تقویت کننده تهیه می شوند. این
        کاغذها، دارای اندود سطحی نمی باشند. گراماژ نرمال آن ها ۶۰ تا ۹۰ گرم بر
        متر مربع می باشد و مهم ترین ویژگی آنها، بالکی بودن این نوع از كاغذهاست.
        کاغذ اداری کاغذ های نرمال مورد استفاده در ماشین های کپی اداری و
        پرینترهای اداری و خانگی تحت عنوان کاغذهای اداری می باشند که از خمیرهای
        حاوی مقادیر بالاي الياف کوتاه و اندکی الیاف بلند، همراه با مقادیر بالایی
        از مواد پر کننده می باشد. عموما در این نوع از کاغذها، به منظور کنترل جذب
        جوهرهای مایه (آبکی) از آهار سطحی در فرآیند تولید استفاده می شود. گراماژ،
        متداول این نوع کاغذها در گستره ی بین ۷۵ تا ۱۰۰ گرم بر متر مربع می باشد.
        ویژگی های برجسته ی این نوع از كاغذها، در قالب زیر قابل تعیین می باشد:
        درجه ی روشنی، ماتی: خواص نوری این نوع از کاغذها بسیار مهم می باشد. حجیمی
        : همانند اکتر کاغذهای چاپ، حجيمي بالایی برای این نوع از کاغذها انتظار می
        رود هدایت الکتریکی: زمانی که در چاپ الکتروفوتوگرافی تونر به سطح کاغذ
        انتقال می یابد، مکانیسم های انتقال شارژ بار سطحی، نمود خاصی پیدا می
        کنند. انتقال حرارت: در فرآیندهای چاپ الکتروفوتوگرافی، به یک سطح کاغذ
        تونر اعمال می شود(سطح چاپی) و به سطح دیگر، کاغذ حرارت اعمال می گردد.
        کاغذهای این نوع چاپ باید حرارت را به خوبی انتقال داده تا در سطح چاپی
        تونر را ذوب نماید. جذب مرکب و رطوبت پذیری: این ویژگھا برای مرکب خشک کنی
        و کیفیت چاپ نهایی در فرآیند چاپ جوهر افشان، حائز اهمیت فراوان می باشد.
      </p>

      <h1>نتیجه گیری</h1>

      <p>
        ارزیابی ویژگی های کاغذ مورد استفاده در عملیات چاپ، متناسب با نوع فرآیند
        چاپ از یک طرف و مرکب مصرف کار چاپی از طرف دیگر حائز اهمیت فراوان میباشد؛
        چراکه در هر مکانیسمی از عملیات چاپ، کاغذ تحت تنش های منحصر به آن عملیات
        و در مواجهه با انواع مختلف مرکب چاپ قرار می گیرد. همچنین، همان طور که
        ذکر شد هر محصول چاپی متناسب با مورد مصرف تحت تنش های محیطی مربوط به خود
        قرار می گیرد. لذا، ارزیابی ویژگیهای کاغذ در انتخاب کاغذ مناسب در هر
        عملیات چاپی، تأمین کننده ی جنبه های کیفی و اقتصادی یک چاپ موفق تلقی می
        گردد.
      </p>
    </MasterItem>
  );
};

export default UsefulItem3;
