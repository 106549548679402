//React
import React, { Component } from "react";
//React
//Images
//Images
//CSS
import styles from "./App.module.css";
//CSS
//Modules
import { Route, Routes } from "react-router-dom";
import Swal from "sweetalert2";
//Modules
// Components For Routes
// *************************** MASTERS ROUTES
import Home from "./Pages/Home/Home";
import E__404 from "./Components/Errors/404__NOT__FOUND/E__404";
import Creators from "./Pages/Creators/Creators";
import EULA from "./Pages/EULA/EULA";
import Terms from "./Pages/Terms/Terms";
import SendArticles from "./Pages/SendArticles/SendArticles";
import ContactUs from "./Pages/ContactUs/ContactUs";
import CustomRedirector from "./Components/CustomRedirector/CustomRedirector";
// *************************** MASTERS ROUTES

/* ************_ Components _************ */
import MainHeader from "./Components/MainHeader/MainHeader";
import TopHeader from "./Components/TopHeader/TopHeader";
import Footer from "./Components/Footer/Footer";
import UseFulItem1 from "./Pages/ComponentsForNewsRoutes/UsefulChimistryItems/UseFulItem1/UseFulItem1";
import UseFulItem2 from "./Pages/ComponentsForNewsRoutes/UsefulChimistryItems/UseFulItem2/UseFulItem2";
import UsefulItem3 from "./Pages/ComponentsForNewsRoutes/UsefulChimistryItems/UsefulItem3/UsefulItem3";
import UsefulItem4 from "./Pages/ComponentsForNewsRoutes/UsefulChimistryItems/UsefulItem4/UsefulItem4";
import UsefulItem5 from "./Pages/ComponentsForNewsRoutes/UsefulChimistryItems/UsefulItem5/UsefulItem5";
import UseFules from "./Pages/ComponentsForNewsRoutes/UsefulChimistryItems/UseFules/UseFules";
import RoutinItem1 from "./Pages/ComponentsForNewsRoutes/RoutinItems/RoutinItem1/RoutinItem1";
import RutinItem2 from "./Pages/ComponentsForNewsRoutes/RoutinItems/RutinItem2/RutinItem2";
import Rutins from "./Pages/ComponentsForNewsRoutes/RoutinItems/Rutins/Rutins";
import RoutinItem3 from "./Pages/ComponentsForNewsRoutes/RoutinItems/RoutinItem3/RoutinItem3";
import RoutinItem4 from "./Pages/ComponentsForNewsRoutes/RoutinItems/RoutinItem4/RoutinItem4";
import RoutinItem5 from "./Pages/ComponentsForNewsRoutes/RoutinItems/RoutinItem5/RoutinItem5";
/* ************_ Components _************ */
class App extends Component {
  state = {
    scrollYPage: 0,
    helpForRedirect: [
      {
        id: "my__Rezume",
        addres: "https://a-goodarzi.ir",
        path: "/a-goodarzi.ir",
      },
      {
        id: "telegram_master",
        addres: "https://t.me/Zei000nab",
        path: "/whats-app-master",
      },
      // {
      //   id: "whatsapp__Master-2",
      //   addres: "https://wa.me/989228135815",
      //   path: "/whats-app-master-2",
      // },
      // {
      //   id: "whatsapp__Master",
      //   addres: "https://wa.me/989190200471",
      //   path: "/whats-app-master-3",
      // },
    ],
    useFulItems: [
      {
        path: "/usefuls",
        element: <UseFules />,
      },
      {
        path: "/usefuls/1",
        element: <UseFulItem1 />,
      },
      {
        path: "/usefuls/2",
        element: <UseFulItem2 />,
      },
      {
        path: "/usefuls/3",
        element: <UsefulItem3 />,
      },
      {
        path: "/usefuls/4",
        element: <UsefulItem4 />,
      },
      {
        path: "/usefuls/5",
        element: <UsefulItem5 />,
      },
    ],
    rutinItems: [
      {
        path: "/rutins",
        element: <Rutins />,
      },
      {
        path: "/rutins/1",
        element: <RoutinItem1 />,
      },
      {
        path: "/rutins/2",
        element: <RutinItem2 />,
      },
      {
        path: "/rutins/3",
        element: <RoutinItem3 />,
      },
      {
        path: "/rutins/4",
        element: <RoutinItem4 />,
      },
      {
        path: "/rutins/5",
        element: <RoutinItem5 />,
      },
    ],
  };

  componentDidMount() {
    window.addEventListener("scroll", () => {
      const maxHeight = window.document.body.scrollHeight - window.innerHeight;
      let percentage = (window.scrollY / maxHeight) * 100;
      this.setState({ scrollYPage: percentage });
    });
    Swal.fire({
      icon: "info",
      title: "به وبسایت شیمی آسان است ، خوش آمدید ",
      text: "امیدوایم لذت ببرید",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    });
    window.scrollTo({ top: 0 });
  }

  render() {
    return (
      <div className={styles.mainContainer}>
        <div className={styles.scrollIndicatiorContainer}>
          <div
            className={styles.inner}
            style={{ width: `${this.state.scrollYPage}%` }}
          ></div>
        </div>
        <TopHeader />
        <MainHeader />
        <Routes>
          {/* MAIN ROUTES */}
          <Route path="/" element={<Home />} />
          <Route path="/creators" element={<Creators />} />
          <Route path="/eula" element={<EULA />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/send-articles" element={<SendArticles />} />
          <Route path="/contact-us" element={<ContactUs />} />
          {this.state.helpForRedirect.map((item) => {
            return (
              <Route
                key={item.id}
                path={item.path}
                element={<CustomRedirector selecedOutsideRoute={item.addres} />}
              />
            );
          })}

          {/* ********************************************************** */}
          {this.state.useFulItems.map((item) => {
            return (
              <Route path={item.path} key={item.path} element={item.element} />
            );
          })}
          {this.state.rutinItems.map((item) => {
            return (
              <Route path={item.path} key={item.path} element={item.element} />
            );
          })}
          {/* *********PAGES FOR ERRORS******** */}
          <Route
            path="*"
            exact
            element={
              <div style={{ overflow: "hidden" }}>
                <E__404 />
              </div>
            }
          />
        </Routes>
        <Footer />
      </div>
    );
  }
}

export default App;
