import React, { Component } from "react";
import MasterItem from "../../MasterItem/MasterItem";
class RutinItem2 extends Component {
  state = {};
  render() {
    return (
      <MasterItem>
        <h1>معرفی ۷ محلول­ پاک کننده خانگی</h1>

        <p>
          این محلول ها را همین حالا در خانه تان بسازید و خانه تان را تمیز کنید!
        </p>

        <p>
          محلول های تمیزکننده، اهمیت زیادی برای سلامت شما و اعضای خانواده دارد.
          اما پاکیزه نگه­داشتن خانه در همه مواقع کار دشواری است. بخصوص اگر
          تعهدات شخصی و شغلی هم داشته باشید، زمان بسیار کمی برای شما باقی
          می­‌ماند. خانه­‌هایی که کودکان کوچکی دارند، می­‌توانند به سرعت کثیف و
          شلوغ شوند.
        </p>

        <h1>یک پاک ­کننده همه تن حریف</h1>

        <p>
          به کمک پاک­ کننده ­های چندکاره که برای همه چیز کاربرد دارند،
          می‌­توانید در وقت تان صرفه­ جویی کنید. به کمک این پاک­ کننده­ ها دیگر
          نیازی ندارید تا در حین فرآیند نظافت منزل، پاک ­کننده­ تان را عوض کنید.
          می­‌توانید دستورالعمل ساخت بعضی از این پاک­ کننده­‌ها را بصورت آنلاین
          پیدا کنید. اما آن­هایی که بیشترین تاثیر را دارند معمولاً ساده ­ترین
          دستورالعمل را دارند. این پاک‌کننده ­ها مزیت دیگری نیز دارند: شما دیگر
          نیازی به صرف هزینه­‌های زیاد برای پاک­ کننده­ های ضد باکتریایی و
          تجملاتی که اصلاً هم برای سلامت شما (و محیط زیست‌مان) مفید نیستند،
          ندارید. ترکیبی ساده از نه واحد آب و یک واحد سرکه، ضدعفونی­ کننده خوبی
          می­‌سازد که قدرت پاک­ کنندگی بالایی دارد و برای سلامت شما مضر نیست.
          می­‌توانید این محلول را در اسپری‌­ها بریزید و آن را برروی کابینت­‌ها و
          کف آشپزخانه و اتاق‌ها بپاشید. برای سطوحی که به راحتی لک می­‌شوند،
          نباید از این محلول استفاده کنید؛ پس قبل از استفاده، آن را در قسمتی از
          سطح موردنظر آزمایش کنید.
        </p>

        <h1>پاک ­کننده کاشی</h1>

        <p>
          کاشی‌­ها می­توانند چرب و لغزنده شوند، بخصوص زمانی که بصورت مداوم در
          معرض آب و روغن قرار می­‌گیرند. دیواره آشپزخانه یا کاشی­ های دیوار
          دستشویی شما باید بصورت منظم تمیز شوند تا همیشه زیبا باشند و چربی­ ها
          روی هم انباشته نشوند. هرچند که بسیاری از پاک­ کننده­های کاشی حاوی مواد
          شیمیایی مضر و آلودگی­های بسیار زیادی هستند که سبب ایجاد مشکلات سلامتی
          می­‌شوند. یکی از بهترین روش ­ها برای پاکسازی کاشی­ ها، محلولی خانگی
          است که حاوی نصف فنجان جوش شیرین، مقداری مایع ظرفشویی و چند قطره از
          روغن­‌های معطر برای برطرف کردن بو می‌­باشد. می‌­توانید از روغن
          اسطوخودوس یا چای درختی برای این‌کار استفاده کنید. تنها کاری که باید
          انجام دهید این است که قبل از اضافه کردن روغن­‌های معطر، مایع ظرفشویی
          را در جوش شیرین بریزید و تا زمانی که محلول کف کند، بهم بزنید. سپس باید
          آن را بر روی یک اسفنج یا مسواک بریزید و تمیز کردن کاشی­‌ها را شروع
          کنید. به همین سادگی کاشی‌­ها به سرعت مثل روز اول‌شان پاکیزه می­‌شوند.
        </p>

        <h1>محلول پاک کننده خانگی برای فرها و گازها</h1>

        <p>
          محلول پاک کننده خانگی برای فرها و گازها تمیز کردن فرها و اجاق گازها
          بسیار دشوار است و بعضی از پاک‌کننده ­ها به اندازه کافی موثر نیستند.
          بعضی از این محلول‌های پاک­‌کننده­ هم اثرات شیمیایی مضری دارند که ممکن
          است تاثیر بدی روی سلامت شما بگذارند. اگر می­‌خواهید تمام چربی ­ها و
          لکه­ های لجباز را از روی گاز و فر خود پاک کنید، می‌­توانید ترکیبی از
          بوره (سدیم بورات)، مقداری مایع دستشویی و آب داغ را امتحان کنید.
          می­‌توانید برای مدتی این ترکیب را بر روی لکه موردنظر پخش کنید و
          بگذارید تا خیس بخورد تا اجزا از هم جدا شوند. سپس می­‌توانید محلول را
          با یک حوله پاک کنید. اگر باز هم با لکه ­هایی مواجه شدید، همین روند را
          تکرار کنید تا فر یا گاز شما بصورت کامل و در مدت زمان کوتاهی تمیز شود.
          برای لکه­ های لجباز می­‌توانید از ترکیب مایع ظرفشویی، جوش شیرین و چند
          قطره آب استفاده کنید. نکته مهم این است که بگذارید تا محلول در چربی
          نفوذ و آن را کمی سست کند.
        </p>

        <h1>محلول تمیز کننده برای سینک</h1>

        <p>
          سینک آشپزخانه و دستشویی به مرور زمان ممکن است خیلی کثیف و لغزنه شود؛
          بخصوص اگر برای مدتی طولانی آن را تمیز نکرده باشید. خبر خوب این است که
          برای اینکار نیازی به خرید چند محلول مختلف ندارید. تنها به دو فنجان جوش
          شیرین، مقداری مایع ظرفشویی، چند قطره آب و چند قطره روغن معطر لیمو نیاز
          دارید. این محلول به شما کمک می­کند تا سینک تان را تمیز و ضدعفونی کنید.
          می­‌توانید این محلول را ترکیب کنید و برروی یک اسفنج یا مسواکی نرم
          بریزید و سینکتان را تمیز کنید. این روش به شما کمک می­کند تا لغزندگی و
          کثیفی سطح را از بین ببرید و از پاکیزگی آن اطمینان خاطر داشته باشید.
        </p>

        <h1>محلول پاک کننده برای پنجره­ ها</h1>

        <p>
          محلول­های گران قیمت و بسیار متنوعی برای تمیز کردن پنجره­ ها در مغازه
          ­ها بفروش می­‌رسد، اما شما می­‌توانید یکی از بهترین محلول­ها را در
          خانه بسازید. دوباره تکرار می ­کنم که مواد لازم برای ساخت این محلول­های
          خانگی همیشه در خانه­ ها موجود است. تنها باید یک­ چهارم فنجان سرکه را
          با مقداری مایع دستشویی طبیعی و آب مخلوط کنید. پس از مخلوط کردن مواد،
          محلول را در یک اسپری بریزید و از آن برای تمیز کردن پنجره ­ها، آینه­ ها
          و دیگر سطوح شیشه‌­ای استفاده کنید. این محلول به شما کمک می­کند تا شیشه
          ها و پنجره­ هایتان را به سرعت و به نحو احسن تمیز کنید. همچنین
          می­توانید برای تاثیر بیشتر مقداری هیدروژن پراکسید و روغن معطر نعناع
          بیابانی را به این ترکیب اضافه کنید. برای ضدعفونی کردن سطح می­ توانید
          از الکل ایزوپروپیل استفاده کنید و اضافه کردن چند قطره روغن نعناع
          بیابانی می­تواند به میزان درخشندگی سطوح کمک کند.
        </p>

        <h1>روشی برای پاکسازی کروم</h1>

        <p>
          برای تمیز کردن ابزار و لوازم پولادی و کرومیک نیازی به خرید پاک ­کننده
          های مخصوص ندارید. محلولی متشکل از صابون ظرفشویی طبیعی، آب و چند قاشق
          سوپ ­خوری سرکه می­تواند به راحتی چربی و کثیفی سطح را از بین ببرد و آن
          را مثل روز اولش کند. تنها کاری که باید بکنید این است که محلول را بخوبی
          مخلوط کنید و آن را در یک اسپری بریزید. در هنگام تمیز کردن کروم، مقداری
          از محلول را روی سطح بپاشید و پس از چند دقیقه آن را پاک کنید.
        </p>

        <h1>محلول مناسب برای تمیز کردن قارچ و کپک</h1>

        <p>
          قارچ و کپک می­تواند یک مشکل جدی باشد. این موضوع آغازکننده بسیاری از
          واکنش­های آلرژیک در میان اعضای آسیب­ پذیر خانواده شما می ­باشد. پس از
          شناسایی این مشکل باید به سرعت آن را از بین ببرید. بهترین روش برای
          انجام اینکار محلولی خانگی متشکل از نصف فنجان جوش شیرین، دو فنجان آب و
          چند قاشق چای­خوری روغن چای درختی می ­باشد. این روغن معطر بدلیل خواص
          آنتی ­بیوتیکیش معروف است و تاثیر بسیار خوبی در مقابل قارچ و کپک دارد.
          باید آب و روغن چای درختی را مخلوط کنید و آن را برروی سطوحی که کپک­ زده
          ­اند بپاشید و بگذارید تا محلول برای مدت بیست و چهار ساعت در آلودگی
          نفوذ کند. سپس همین محلول را با جوش شیرین مخلوط کنید تا حالتی خمیری
          بگیرد و برای تمیز کردن سطوح از آن استفاده کنید.
        </p>
      </MasterItem>
    );
  }
}

export default RutinItem2;
