//React
import React, { Component, PureComponent } from "react";
//React
import { Link } from "react-router-dom";
//Images
//Images
//CSS
import styles from "../../App.module.css";
//CSS
//Modules And Components
import NewestContents from "../../Components/NewestContents/NewestContents";
import TopNews from "../../Components/TopNews/TopNews";
import Fade from "react-reveal/Fade";
//Modules And Components

import img1 from "./../../images/New folder/useful/1.jpg";
import img2 from "./../../images/New folder/useful/2.jpg";
import img3 from "./../../images/New folder/useful/3.jpg";
import img4 from "./../../images/New folder/useful/4.jpg";

import img5 from "./../../images/New folder/rutin/1.jpg";
import img6 from "./../../images/New folder/rutin/2.jpg";
import img7 from "./../../images/New folder/rutin/3.jpg";
import img8 from "./../../images/New folder/rutin/4.jpg";

import ComputerContainer from "../../Components/ComputerContainer/ComputerContainer";

class Home extends PureComponent {
  state = {};
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  render() {
    return (
      <Fade bottom>
        <NewestContents />
        {/* <TopNews /> */}
        <Link id={"test"} to="/usefuls" className={styles.middy}>
          کاربردی
        </Link>
        <ComputerContainer
          computerNewsLittleItems={[
            {
              id: "use1",
              newsName: "8 ضرر مواد آرایشی",
              newsImage: img1,
              newsTextContent: "انواع ضرر های مواد آرایشی",
              writeIn: "",
              writeBy: "    زینب فراهانی",
              routeToNavigate: "/usefuls/1",
            },
            {
              id: "computexr-item-1",
              newsName: "چرا آب و روغن مخلوط نمیشوند ؟",
              newsImage: img2,
              newsTextContent: "علت چیست ؟",
              writeIn: "",
              writeBy: "    زینب فراهانی",
              routeToNavigate: "/usefuls/2",
            },
            {
              id: "computer-citem-1",
              newsName: "مناسب ترین کاغذ چیست ؟",
              newsImage: img3,
              newsTextContent: "بهترین کاغذ برای چاپ چیست ؟",
              writeIn: "",
              writeBy: "    زینب فراهانی",
              routeToNavigate: "/usefuls/3",
            },
          ]}
          rightBigItem={{
            id: "topNews__computer__1h8__4__2022___1",
            newsName: "علت چروک شدن لباس چیست ؟",
            newsImage: img4,
            newsTextContent: "چرا لباسم کسر آب میکنه ؟",
            writeIn: "",
            writeBy: "    زینب فراهانی",
            routeToNavigate: "/usefuls/4",
          }}
        />
        <Link to="/rutins" className={styles.middy}>
          روزمره
        </Link>
        <ComputerContainer
          computerNewsLittleItems={[
            {
              id: "usex1",
              newsName: "پاک کردن لکه از روی فرش",
              newsImage: img5,
              newsTextContent: "چگونه لکه نوشبه را پاک کنیم ؟",
              writeIn: "",
              writeBy: "    زینب فراهانی",
              routeToNavigate: "/rutins/1",
            },
            {
              id: "compfuter-itedm-1",
              newsName: "محلول های پاک کنندگی خانگی",
              newsImage: img6,
              newsTextContent: "7 محلول خانگی",
              writeIn: "",
              writeBy: "    زینب فراهانی",
              routeToNavigate: "/rutins/2",
            },
            {
              id: "compauter-item-v",
              newsName: "لکه های سخت کدامند ؟",
              newsImage: img7,
              newsTextContent: "نحوه پاک کردن لکه های سخت",
              writeIn: "",
              writeBy: "    زینب فراهانی",
              routeToNavigate: "/rutins/3",
            },
          ]}
          rightBigItem={{
            id: "topNexws__computer__18__4__2x022___1",
            newsName: "روش حذف بوی نامطبوع خانه",
            newsImage: img8,
            newsTextContent: "چطور بوی بد خانه را از بین ببریم ؟",
            writeIn: "",
            writeBy: "    زینب فراهانی",
            routeToNavigate: "/rutins/4",
          }}
        />
      </Fade>
    );
  }
}

export default Home;
