import React from "react";
import MasterItem from "../../MasterItem/MasterItem";

const UsefulItem4 = () => {
  return (
    <MasterItem>
      <h1>چرا لباس ها بعد از شستن کوچک می شوند؟</h1>

      <p>
        لباسی را می خرید و می پوشید. بعد از اولین بار شستشو ناگهان متوجه تغییر
        بزرگی در لباس مورد علاقه ی خود می شوید. لباس شما آب رفته و کوچک شده است.
        این تغییر و دفرمه شدن برای بسیاری از افرادی که نسبت به پوشش خود توجه می
        کنند بسیار ناراحت کننده است. ضمن این که لباس مورد علاقه ی آن ها شکل و
        ظاهر اولیه ای که باعث دلچسب شدن آن را داشته دیگر ندارد
      </p>

      <p>
        چرا لباس ها بعد از شستن کوچک می شوند؟ دلیل آن تفاوت جنس پارچه، بافت و
        نحوه تولید لباس هاست. باید دقت کنید که هر جنس پارچه و لباسی ممکن است در
        هنگام شستشو واکنش های متفاوتی را از خود نشان دهد. الیاف طبیعی مانند پشم،
        پنبه و بامبو بیشتر از الیاف دست ‌ساز مانند پلی استر، آکریلیک و نایلون کش
        می ‌آیند. همچنین پارچه ‌هایی که حالت کششی خود را از دست داده ‌اند، بیشتر
        از لباس‌ های تنگ، کش می ‌آیند. بافت‌های پارچه‌ای شل بیشتر از بافت‌های
        پارچه‌ای محکم کشیده‌ می شوند، اما بافت‌های شل همچنین در معرض آب، گرما و
        تحریک راحت تر از یک بافت‌های محکم تنگ شده یا آب می روند.
      </p>

      <h1>آب رفتن لباس یا کوچک شدن لباس چه زمانی رخ می‌دهد؟</h1>

      <p>
        قطعاً می توان گفت که در زمان شستن، لباس آب می رود و یا کوچک می شود. اما
        اگر بخواهیم دقیق تر و موشکافانه تر به آن نگاه کنیم باید بافت پارچه را
        بررسی کنیم. تغییر سایز لباس بعد از شستن برای بسیاری از لباس ها با جنس
        های مختلف اتفاق می افتد ولی این موضوع برای پارچه های نخی بیشتر و عیان تر
        پیش می آید. در هنگام تولید پارچه های نخی، تولید‌کنندگان با فشر‌ده سازی
        تار و پود پارچه، یک قوام نسبی را در آن ها ایجاد می کنند که باعث می شود
        تا شکل پارچه به صورت یک دست و صاف و منظم باشد. زمانی که پارچه ها، با آب
        گرم شسته می شوند، بافت منسجم آن ها کمی آزاد می شود و تار و پود از حالت
        اولیه خود خارج می شوند. پس از آب کشی، و در زمان خشک شدن، بافت پارچه به
        حالت قبل باز می گردد، اما چون فرم اولیه بافت از بین رفته، کمی کوچک می
        شود و آب رفتن لباس‌ و یا به عبارتی تغییر سایز و کوچک شدن لباس، درست در
        همین زمان اتفاق می افتد.
      </p>

      <h1>چگونه لباس آب رفته را درست کنیم؟</h1>

      <p>
        لباسم آب رفته چکار کنم؟ الان ما دیگر می دانیم که چرا لباس هایمان پس از
        شستشو آب می روند. اما اکنون می خواهیم بدانیم که چگونه لباس آب رفته را
        درست کنیم و زمانی که به لباس آسیب رسیده است چه باید کرد؟ لباس نخی، جین و
        کتان، پشمی، ریون و الیاف مصنوعی از جمله لباس هایی هستند که آب می روند.
      </p>

      <p>
        - لباس نخی: چگونه لباس نخی آب رفته را درست کنیم؟ برای رفع مشکل آب رفت
        لباس نخی باید در یک لگن یا تشت مقداری آب ولرم و نرم کننده یا شامپوی بچه
        بریزید و حل کنید. سپس لباس نخی خود را به مدت 20 تا 30 دقیقه داخل این
        محلول قرار داده و در انتها پس از شستن لباس، آب اضافی آن را با پیچیدن
        حوله به دور آن بگیرد. حال لباس را روی بند در هوای آزاد پهن کنید. - لباس
        جین و کتان: لباس های جین و کتان به دلیل نوع الیاف بکار رفته در آن ها و
        جنس پارچه هایشان مقاومت بیشتری نسبت به سایر لباس ها از خود نشان می دهند.
        زمانی که شما شلوار جین خود را می شویید و پس از خشک شدن آن را می پوشید
        انگاری برای اولین بار است که این لباس را به تن می کنید چرا که در هنگام
        شستشو لباس جمع شده و آب رفته است. ولی همین شلوار تنگ شده را اگر یکی دو
        بار بپوشید مجدداً به حالت اولیه و راحت خود بر می گردد. اما حالا می
        خواهیم لباس جین خود را در زمان کوتاه تری به حالت اولیه برگردانیم. برای
        این کار درون وان یا لگن خود را با آب گرم (در حدی که داغ نباشد) پر کنید و
        لباس های جین خود را کنار هم داخل آن بگذارید و اجازه دهید تا به مدت 15
        دقیقه کاملاً آب را به خود جذب کنند. سپس آب را تخلیه و لباس را در جای
        مناسب پهن کرده و اجازه دهید تا خشک شود.
      </p>

      <p>
        - لباس پشمی: لباس پشمی آب رفته، چکار کنیم؟ آب رفتن لباس بافتنی یا همان
        پشمی زیاد ممکن است اتفاق بیافتد. اما راه حل آن این است که مانند لباس های
        نخی لگن یا تشت را از آب گرم پر کنید و به ازای هر یک لیتر یک تا دو قاشق
        غذاخوری سرکه سفید به آن اضافه کنید و سپس لباس های پشمی خود را درون این
        محلول غوطه ور کنید و پس از گذشت چند دقیقه لباس را آب کشی کنید و در هوای
        آزاد در جای مناسب پهن کنید تا خشک شود.
      </p>

      <p>
        - لباس های ریون و الیاف مصنوعی : لباس مجلسیم تنگ شده چیکار کنم؟ لباس های
        مجلسی اکثراً از جنس ریون و الیاف مصنوعی هستند. ظرافت پارچه‌های ریون و
        الیاف مصنوعی باعث شده تا نسبت به حرارت حساس باشند و در حرارت بالا آب
        بروند. برای برگرداندن لباس های ریون به حالت اولیه باید مانند لباس های
        نخی داخل وان یا سینک یا تشت را با آب با دمای معمولی و نرمال پر کرده و به
        ازای هر یک لیتر آب یک قاشق غذاخوری نرم کننده و یا شامپو بچه بریزید و
        کاملاً حل کنید. سپس لباس خود را داخل آن محلول قرار داده و پس از گذشت 15
        تا 20 دقیقه آن را خارج کرده و آب اضافی آن را بگیرید. فقط توجه داشته
        باشید که به هیچ وجه لباس را نچلانید و اجازه دهید خشک شود. فقط به این
        نکته توجه داشته باشید که این ترفندها قطعی و تضمین شده نیست و ممکن است در
        نهایت لباس شما به سایز مورد نظر نرسد ولی امتحان کردن آن می تواند بی ضرر
        باشد.
      </p>

      <h1>چگونه از آب رفتن لباس جلوگیری کنیم؟</h1>

      <p>
        جمع شدن لباس از معضلاتی است که در بسیاری از موارد به دلیل نداشتن آگاهی و
        اطلاعات کافی اتفاق می افتد. اما شما می توانید با استفاده از تکنیک های
        صحیح شست و شو و کسب اطلاعات لازم در این زمینه از آب رفتن و یا گشاد شدن
        لباس های خود جلوگیری کنید. نکاتی را که در قسمت قبل گفتیم اگر پیش از
        شستشوی لباس به آن توجه کنید و همان دستورالعمل را انجام دهید دیگر لباس
        شما آب نمی رود و یا تنگ نمی شود و شما مجبور به انجام کار اضافه نمی شوید.
        اما در این بخش نکات مهمی را ارائه خواهیم کرد که قبل از شستشوی لباس باید
        به آن توجه کنید:
      </p>

      <p>
        - در مورد الیاف طبیعی حتماً به برچسب «از قبل آب رفته» توجه کنید. چرا که
        اگر لباسی این برچسب را نداشته باشد به این معناست که ممکن است لباس پس از
        اولین شستشو آب برود و یا تغییر فرم دهد. اگر الیاف قبل از اینکه بافته
        شوند یا پارچه قبل از دوخته شدن خیس خورده باشند و آب رفته باشند، در طول
        مدت استفاده کمتر آب خواهند رفت. 2- برچسب لباس ها را حتما بخوانید تا از
        روش مراقبت و نگهداری و شستشوی لباس اطلاع پیدا کنید. بعضی لباس ها نیاز
        دارند تا با آب سرد شسته شوند و یا باید با دست شسته شوند. پس حتماً به
        برچسب ها دقت کنید. 3- استفاده از آب سرد هنگام شست و شو می تواند آسیب
        کمتری به پارچه و یا لباس شما وارد سازد. به همین علت است که بسیاری از
        تولیدکنندگان لباس روی اجناس خود برچسب «با آب سرد شسته شود» می زنند تا
        کیفیت کار خود را تضمین کنند. 4- به برنامه های ماشین لباس شویی دقت کنید.
        سعی کنید برای لباس های حساس تنظیمات دقیق تری انجام دهید و از برنامه های
        ملایم تری برای شست و شو استفاده کنید تا عمر لباس های شما بیشتر شود. چرا
        که شست و شوی طولانی با سرعت چرخش بالا می تواند آسیب بیشتری در دراز مدت
        به لباس شما وارد کند. 5- لباس ها را پس از شست و شو در هوای آزاد خشک کنید
        تا کمتر دچار آب رفت و یا شل شدن بشوند. پارچه های پشمی و بافت ها و یا
        پارچه های کشی اگر روی سطح صاف و به دور از کش آمدن خشک شوند خیلی بهتر
        است.
      </p>

      <h1>چرا لباس های خیس تنگ می شوند؟</h1>

      <p>
        زمانی که شما لباس خشکی را می پوشید کاملاً آزاد و راحت است و به بدن نمی
        چسبد. اما اگر همین لباس خشک کمی خیس شود و یا شما عرق کنید و تن شما خیس
        شود بلافاصله لباس به تن شما می چسبد. دلیل اینکه لباس های خیس تنگ می شوند
        به خاطر این است که آب هم با پارچه و هم با بدن خاصیت دگرچسبی دارد. یعنی
        بین دو جنس متفاوت خاصیت چسبندگی به وجود می آید و آب با بدن و با پارچه
        چنین خاصیتی را دارند. به همین علت است که زمان عرق کردن بدن و یا خیس شدن،
        لباس به تن شما چسبیده و آزاردهنده می شود.
      </p>

      <h1>چرا آب سرد لباس را تنگ می کند؟</h1>

      <p>
        زمانی که لباس ها را با آب گرم یا داغ می شویید به دلیل حالت انبساطی آب
        گرم، بافت پارچه در بسیاری از مواقع شل شده و در نتیجه لباس گشاد می شود.
        اما اگر همان لباس را با آب سرد بشویید به دلیل حالت انقباضی آب سرد، بافت
        پارچه از هم گسسته نخواهد شد و حالت خود را حفظ خواهد کرد و در بعضی مواقع
        باعث تنگ شدن لباس می گردد.
      </p>

      <h1>جمع بندی</h1>
      <p>
        چرا لباس ها بعد از شست و شو کوچک می شوند، چگونه لباس آب رفته را ترمیم
        کنیم و چگونه از آب رفت لباس جلوگیری کنیم، این ها بخشی از مطالبی بود که
        در این مقاله با هم یاد گرفتیم. همچنین آموختیم که تمامی این مسائل به جنس
        پارچه، بافت و نحوه تولید لباس بستگی دارد.
      </p>
    </MasterItem>
  );
};

export default UsefulItem4;
