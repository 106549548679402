//React
import React, { Component } from "react";
//React

//Images
import { GoCalendar } from "react-icons/go";
import { BsPencil } from "react-icons/bs";
//Images

//CSS
import styles from "./ComputerContainer.module.css";
import { Link } from "react-router-dom";
//CSS

//Modules
//Modules

class ComputerContainer extends Component {
  constructor() {
    super();
    this.testi = null;
  }
  state = {};

  render() {
    return (
      <div className={styles.computerContainerr}>
        <div className={styles.rightContents}>
          <div className={styles.leftLittleItems}>
            {this.props.computerNewsLittleItems.map((item) => {
              return (
                <Link
                  to={item.routeToNavigate}
                  className={styles.littleItem}
                  key={item.id}
                >
                  <div className={styles.contents}>
                    <h4>{item.newsName}</h4>
                    <h6>
                      {item.writeIn} <BsPencil />
                      {item.writeBy}
                    </h6>
                  </div>
                  <div
                    className={styles.picture}
                    style={{ backgroundImage: `url("${item.newsImage}")` }}
                  ></div>
                </Link>
              );
            })}
          </div>
          <Link
            to={this.props.rightBigItem.routeToNavigate}
            className={styles.rightBigItem}
          >
            <div
              className={styles.imageContainer}
              style={{
                backgroundImage: `url("${this.props.rightBigItem.newsImage}")`,
              }}
            ></div>
            <div className={styles.newsName}>
              {this.props.rightBigItem.newsName}
            </div>
            <div className={styles.newsAbout}>
              {this.props.rightBigItem.newsTextContent}
            </div>
            <div className={styles.writenBy}>
              {this.props.rightBigItem.writeIn} <BsPencil />
              {this.props.rightBigItem.writeBy}
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default ComputerContainer;
