import React, { Component } from "react";

import styles from "./Rutins.module.css";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { BsPencil } from "react-icons/bs";

import img1 from "./../../../../images/New folder/rutin/1.jpg";
import img2 from "./../../../../images/New folder/rutin/2.jpg";
import img3 from "./../../../../images/New folder/rutin/3.jpg";
import img4 from "./../../../../images/New folder/rutin/4.jpg";
import img5 from "./../../../../images/New folder/rutin/5.jpg";

class Rutins extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0 });
  }
  state = {
    rutinItems: [
      {
        id: "rut1",
        newsName: "پاک کردن لکه از روی فرش",
        newsTextContent: "چگونه لکه نوشبه را پاک کنیم ؟",
        newsImage: img1,
        writeIn: "",
        writeBy: "",
        routeToNavigate: "/rutins/1",
      },
      {
        id: "rut2",
        newsName: "محلول های پاک کنندگی خانگی",
        newsImage: img2,
        newsTextContent: "7 محلول خانگی",
        writeIn: "",
        writeBy: "",
        routeToNavigate: "/rutins/2",
      },
      {
        id: "rut3",
        newsName: "لکه های سخت کدامند ؟",
        newsImage: img3,
        newsTextContent: "نحوه پاک کردن لکه های سخت",
        writeIn: "",
        writeBy: "",
        routeToNavigate: "/rutins/3",
      },
      {
        id: "rut4",
        newsName: "روش حذف بوی نامطبوع خانه",
        newsImage: img4,
        newsTextContent: "چطور بوی بد خانه را از بین ببریم ؟",
        writeIn: "",
        writeBy: "",
        routeToNavigate: "/rutins/4",
      },
      {
        id: "rut5",
        newsName: "قوی ترین چسب جهان ؟",
        newsImage: img5,
        newsTextContent: "معرفی تعدادی از چست ها",
        writeIn: "",
        writeBy: "",
        routeToNavigate: "/rutins/5",
      },
    ],
  };

  render() {
    return (
      <Fade left>
        <div className={styles.computerPage} ref={this.ComputerPageRef}>
          <div className={styles.newsItemsMasterContainer}>
            {this.state.rutinItems.reverse().map((item) => {
              return (
                <Link
                  to={`${item.routeToNavigate}`}
                  className={styles.computerNewsItem}
                  key={item.id}
                >
                  <div
                    className={styles.picContainer}
                    style={{ backgroundImage: `url("${item.newsImage}")` }}
                  ></div>
                  <div className={styles.absoluteItem}></div>

                  <div className={styles.contentContainer}>
                    <p className={styles.newsName}>{item.newsName}</p>
                    <p className={styles.newsName}>{item.newsTextContent}</p>
                    <p className={styles.writenBy}>
                      زینب فراهانی
                      <BsPencil />
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </Fade>
    );
  }
}

export default Rutins;
