//React
import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
//React
//Images
import { FiMoon } from "react-icons/fi";
//Images
//CSS
import styles from "./MainHeader.module.css";
//CSS
//Modules
import { GiHamburgerMenu } from "react-icons/gi";
//Modules
class MainHeader extends Component {
  constructor() {
    super();
    this.nightModeRef = React.createRef();
    this.hamburgerMenuContentsRef = React.createRef();
  }
  state = {
    night: false,
    currLocation: "/",
    colorForHeaderText: "#000",
    selectedColorForTop:
      "linear-gradient(333deg, rgba(43,1,121,1) 0% 0%, rgba(121,56,242,1) 100% 100%)",
  };
  makeHeaderHidden = () => {
    this.hamburgerMenuContentsRef.current.style.visibility = "hidden";
    this.hamburgerMenuContentsRef.current.style.display = "none";
    this.hamburgerMenuContentsRef.current.style.opacity = "0";
  };

  render() {
    return (
      <div
        className={`${styles.MainHeader} ${
          (
            (window.scrollY /
              (window.document.body.scrollHeight - window.innerHeight)) *
              100 >
            3
              ? true
              : false
          )
            ? styles.fixx
            : null
        }`}
        style={{
          position: (
            (window.scrollY /
              (window.document.body.scrollHeight - window.innerHeight)) *
              100 >
            3
              ? true
              : false
          )
            ? "fixed"
            : "relative",

          top: (
            (window.scrollY /
              (window.document.body.scrollHeight - window.innerHeight)) *
              100 >
            3
              ? true
              : false
          )
            ? "0"
            : "0",

          zIndex: 19,
          backgroundColor: (
            (window.scrollY /
              (window.document.body.scrollHeight - window.innerHeight)) *
              100 >
            3
              ? true
              : false
          )
            ? "#757575d8"
            : "transparent",
        }}
      >
        <div className={styles.left}>
          <div className={styles.linksContainer}>
            <NavLink
              to="/"
              style={({ isActive }) => ({
                background: isActive ? this.state.selectedColorForTop : "#fff",
                color: isActive ? "#fff" : this.state.colorForHeaderText,
              })}
              onClick={() => {
                this.setState({
                  ...this.state,
                  currLocation: "/",
                });
              }}
            >
              خانه
            </NavLink>
            <NavLink
              to="/usefuls"
              style={({ isActive }) => ({
                background: isActive ? this.state.selectedColorForTop : "#fff",
                color: isActive ? "#fff" : this.state.colorForHeaderText,
              })}
              onClick={() => {
                this.setState({
                  ...this.state,
                  currLocation: "usefuls",
                });
              }}
            >
              کاربردی
            </NavLink>
            {/* <NavLink to="/newest-content">سخت افزار </Link> */}
            <NavLink
              to="/rutins"
              style={({ isActive }) => ({
                background: isActive ? this.state.selectedColorForTop : "#fff",
                color: isActive ? "#fff" : this.state.colorForHeaderText,
              })}
              onClick={() => {
                this.setState({
                  ...this.state,
                  currLocation: "rutins",
                });
              }}
            >
              روزمره
            </NavLink>
            {/* <NavLink
              to="/game-news"
              style={({ isActive }) => ({
                background: isActive ? this.state.selectedColorForTop : "#fff",
                color: isActive ? "#fff" : this.state.colorForHeaderText,
              })}
              onClick={() => {
                this.setState({
                  ...this.state,
                  currLocation: "game-news",
                });
              }}
            >
              بازی
            </NavLink> */}
          </div>
          <div
            className={styles.hamburgerContainer}
            style={{ display: window.innerWidth > 800 ? "none" : "block" }}
            onClick={() => {
              this.hamburgerMenuContentsRef.current.style.visibility =
                "visible";
              this.hamburgerMenuContentsRef.current.style.display = "flex";
              this.hamburgerMenuContentsRef.current.style.opacity = "1";
            }}
          >
            <GiHamburgerMenu />
          </div>
          <div
            className={styles.hamburgerMenuContents}
            ref={this.hamburgerMenuContentsRef}
          >
            <div className={styles.close}></div>
            <div
              className={styles.absoluteOpacity}
              onClick={this.makeHeaderHidden}
            ></div>
            <NavLink onClick={this.makeHeaderHidden} to="/">
              خانه
            </NavLink>
            <NavLink onClick={this.makeHeaderHidden} to="/rutins">
              روزمره
            </NavLink>
            <NavLink onClick={this.makeHeaderHidden} to="/usefuls">
              کاربردی
            </NavLink>
          </div>
        </div>
        <div
          className={styles.right}
          ref={this.nightModeRef}
          onClick={() => {
            this.nightModeRef.current.style.color = "#fff";
            document.body.classList.toggle("night");
            this.setState({
              night: !this.state.night,
            });
          }}
        >
          <FiMoon />
        </div>
      </div>
    );
  }
}

export default MainHeader;
