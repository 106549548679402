import React, { Component } from "react";
import MasterItem from "../../MasterItem/MasterItem";

class RoutinItem3 extends Component {
  state = {};
  render() {
    return (
      <MasterItem>
        <h1>روش پاک کردن 11 نوع لکه سخت از روی لباس و ...</h1>

        <p>
          فرض کنید در یک مهمانی مهم آبمیوه قرمز یا سس گوجه‌فرنگی یا چکه‌ای روغن
          و چربی یا قهوه روی لباس‌تان بریزد. از همان لحظه است که تمام فکر و
          ذکرتان می‌شود پاک کردن آن لکه سمج که احساس می‌کنید لحظه به لحظه بیشتر
          و بدتر می‌شود. در ادامه ۱۱ روش سریع برای پاک کردن انواع لکه از روی
          لباس و انواع پارچه رنگی و سفید را بخوانید.
        </p>
        <h1>۱- پاک کردن اورژانسی لکه‌ها</h1>

        <p>
          لکه‌هایی مثل لکه آبمیوه قرمز و سوپ گوجه‌فرنگی اگر روی لباس‌تان بریزند
          اعصاب‌خردکن هستند. برای پاک کردن این لکه‌ها دو راه‌حل پیش رو دارید.
          اول این‌که به هیچ‌وجه لکه را با یک پارچه زبر و خشن نسابید. شما به یک
          پارچه سفید نرم نیاز دارید. اگر هم پارچه سفید دم دست ندارید یک تکه نان
          سفید کفایت می‌کند. لکه‌هایی که بر پایه چربی هستند مثل لکه سس سالاد با
          لوازم روی میز پاک می‌شوند. مقداری نمک روی این نوع لکه‌ها بریزید اجازه
          دهید نمک در لکه حل شود و کمی آن را با نوک انگشت بسابید. سپس با یک برس
          آن را از روی لباس بزدایید. نمک به‌راحتی می‌تواند لکه چربی را به خود
          جذب کند.
        </p>

        <h1>۲- پاک کردن لکه چمن</h1>

        <p>
          در بسیاری موارد بعد از پیک‌نیک رفتن یا بازی فوتبال در زمین چمن لکه‌های
          سبز چمن روی لباس شما باقی می‌ماند و با شستن در ماشین لباسشویی یا شستن
          با دست پاک نمی‌شود. اما نگران نباشید، لکه‌های سبز چمن به آن سمجی که
          فکر می‌کنید هم نیستند.
        </p>

        <p>
          یکی از بهترین مواد برای پاک کردن لکه سبز چمن استفاده از خمیر دندان
          است. مقداری خمیر دندان سفید روی لکه چمن بمالید و با یک مسواک کهنه کمی
          آن را بسابید. یادتان باشد که خمیر دندان حتما باید خمیری باشد نه خمیر
          دندان ژل مانند. اگر با یک بار استفاده از خمیر دندان لکه چمن پاک نشد،
          چندین بار این کار را تکرار کنید. خمیردندان علاوه بر لکه چمن، لکه جوهر
          را هم پاک می‌کند.
        </p>

        <h1>۳- لکه خون</h1>

        <p>
          لکه خون از آن لکه‌های اجتناب‌ناپذیر و غیر قابل پیش‌بینی است. اما پاک
          کردن لکه خون آن‌قدرها هم که به نظر می‌رسد کار سخت و وحشتناکی نیست.
          برای پاک کردن لکه خون کافی است از آب‌اکسیژنه ۳ درصد استفاده کنید. اگر
          لکه خون خشک شده است ابتدا با ناخن یا نوک چاقو رد خون خشک شده را
          بخراشید. سپس چند قطره آب‌اکسیژنه روی لکه بریزید، با آب بشویید و سپس در
          ماشین لباسشویی بیندازید. اگر لکه خون را قبل از خشک شدن با آب‌اکسیژنه ۳
          درصد تمیز کنید شانس شما برای پاک شدن آن بیشتر است. یک راه‌حل دیگر برای
          پاک کردن لکه خون استفاده از جوش‌ شیرین است. مقداری جوش شیرین روی لکه
          خون بریزید، کمی بسابید و سپس با آب معمولی بشویید. علاوه بر این برای
          پاک کردن لکه خون می‌توانید لباس لکه‌دار شده را با بیشترین سرعت ممکن در
          یک ظرف حاوی آب نمک سرد بخیسانید. پس از آن که لباس به مدت ۳ تا ۴ ساعت
          خیس خورد، لکه را با یک ماده شوینده مایع شستشو داده و با استفاده از
          ماشین لباسشویی یا با دست بشویید.
        </p>

        <h1>۴- پاک کردن لکه‌های یقه</h1>

        <p>
          برای پاک کردن لکه‌های یقه لباس‌ها لازم نیست حتما از لکه‌برهای شیمیایی
          مخصوص یقه استفاده کنید یا حتما لباس را به خشکشویی ببرید. لکه روی یقه
          لباس معمولا زرد است. برای پاک کردن لکه زرد از روی لباس رنگی یا سفید
          کافی است کمی شامپو بچه روی لکه‌ها بریزید و دو طرف یقه را خوب به هم
          بمالید تا کاملا به شامپو آغشته شود. ۱۵ تا ۳۰ دقیقه صبر کنید و سپس لباس
          را آبکشی کنید.
        </p>

        <h1>۵- پاک کردن لکه رژ لب</h1>

        <p>
          لکه رژلب از آن دست لکه‌های سمج است که به‌آسانی پاک نمی‌شود. همه چیز به
          جنس پارچه لباس‌تان بستگی دارد. اگر لباس از جنس پشم، کشمیر یا ابریشم
          باشد بهتر است لباس را به خشکشویی بدهید تا آسیب نبیند. در موارد دیگر
          ابتدا لکه رژلب را از روی لباس بخراشید. چند عدد دستمال کاغذی را روی یک
          سطح سفت بگذارید و قسمت کثیف شده پارچه را روی دستمال‌ها بگذارید. سپس
          کمی مایع ظرفشویی پشت قسمت لکه دار شده پارچه بریزید. حالا با استفاده از
          یک مسواک خیس روی لکه از بیرون به داخل بکشید. وقتی کمی مرطوب و صابونی
          شد، لباس را داخل ماشین لباسشویی بیندازید. اگر لکه حتی بعد از شستشو روی
          لباس ماند همین مراحل را باز هم تکرار کنید. زمانی لباس را داخل ماشین
          لباسشویی بیندازید که لکه کاملا تمیز شده باشد.
        </p>

        <h1>۶- پاک کردن لکه روغن اتومبیل</h1>

        <p>
          برای پاک کردن لکه روغن اتومبیل یا همان گریس از روی لباس نباید به شستن
          لباس دل ببندید. برای پاک کردن این لکه‌های سمج بهتر است از موادی مثل
          آرد ذرت یا همان پودر نشاسته استفاده کنید. مقداری پودر نشاسته روی لکه
          روغن اتومبیل بریزید و چند دقیقه اجازه بدهید تا پودر، چربی را به خودش
          جذب کند. سپس با یک برس پودر را از روی لباس تمیز کنید. یک ماده موثر
          دیگر برای پاک کردن لکه روغن اتومبیل اسپری موسوم به WD-۴۰ است. این
          اسپری همه کاره به‌راحتی لکه روغن را از روی لباس پاک می‌کند، کافی است
          مقداری از آن را روی لکه چربی اسپری کنید و ۳۰ دقیقه صبر کنید. سپس لباس
          را با مقداری صابون دستشویی یا پودر شستشوی لباس بشویید. اگر آثار لکه
          هنوز باقی است این مراحل را یک بار دیگر تکرار کنید.
        </p>

        <h1>۷- پاک‌کردن لکه چربی طبیعی</h1>

        <p>
          عمولا روبالشی‌ها بر اثر چربی ترشح شده از کف سر یا صورت و بدن بعد از
          مدتی چرب به نظر می‌رسند. پاک کردن لکه چربی قدیمی از روی لباس خیلی مشکل
          است. بهترین روش برای از بین بردن این چربی استفاده شامپو یا سایر مواد
          شوینده است. کافی است چند دقیقه روبالشی‌ها را در محلول آب و این مواد
          شوینده بخیسانید و سپس آبکشی کنید. یک راه دیگر برای از بین بردن این
          لکه‌های چربی طبیعی استفاده از گچ سفید است. کافی است گچ سفید را چند بار
          روی لکه‌های چربی بکشید و ۱۵ دقیقه صبر کنید. با یک برس گچ‌ها را پاک
          کنید یا روبالشی را بتکانید و سپس آن را با پودر ماشین و آب بشورید، برای
          روبالشی‌های سفید می‌توانید از مقداری مایع سفیدکننده هم استفاده کنید.
          روبالشی‌های رنگی را در ماشین لباسشویی بیندازید و از پودرهای دارای
          آنزیم برای شستشو استفاده کنید. همچنین برای پاک کردن لکه چربی یا روغن
          از روی لباس ساتن پودر تالک را بر روی لکه بپاشید و به‌آرامی آن را
          بمالید. چند دقیقه آن را به همان صورت گذاشته سپس به وسیله‌ برس نرمی با
          ملایمت آن را برس بکشید و تمیز کنید.
        </p>

        <h1>۸- تمیز کردن لکه جوهر</h1>

        <p>
          معمولا آقایانی که عادت دارند خودکار، خودنویس یا روان‌نویس را در جیب
          پیراهن یا کت‌شان بگذارند، لکه‌های جوهر ترشح شده روی لباس غافلگیرشان
          می‌کند. با توجه به نوع لکه جوهر یا لکه ماژیک از روی لباس باید از مواد
          مختلف برای پاک کردن آن استفاده کرد. اگر لکه جوهر خودکار روی لباس‌تان
          ریخته است چند عدد دستمال توالت را روی لکه بگذارید. سپس چند قطره الکل
          روی ناحیه مورد نظر بریزید. برای لکه‌های بزرگ باید ۱۵ دقیقه صبر کنید.
          سپس با یک پارچه تمیز یا اسفنج الکل را را از روی لکه پاک کنید. لباس را
          آبکشی کنید و اگر هنوز ردی از لکه باقی مانده دوباره این کار را تکرار
          کنید. بعد از پاک شدن لکه، لباس را با آب گرم آبکشی کنید. برای پاک کردن
          لکه جوهر روان‌نویس ابتدا لکه را با آب سرد بشویید. سپس با لباس را در آب
          گرم بیندازید و مقداری پودر لباسشویی روی لکه بریزید. چند قطره آمونیاک
          هم روی لکه بریزید. محل لکه را به آرامی بمالید و اجازه دهید ۳۰ تا شصت
          دقیقه خیس بخورد یا شب تا صبح در این محلول باقی بماند و سپس آبکشی کنید
          و در ماشین لباسشویی بیندازید. اگر آثار لکه باقی بود چند بار دیگر این
          کار را تکرار کنید.
        </p>

        <h1>۹- لکه قهوه</h1>

        <p>
          پاک کردن لکه قهوه در لحظات اول بسیار آسان‌تر از زمانی است که لکه کهنه
          شده است. در مورد پارچه‌های ابریشم و ترمه کار را به خشکشویی بسپارید.
          اگر لکه روی یک لباس است به مدت ۳ تا ۵ دقیقه آن را از سمت پشت لکه، زیر
          شیر آب سرد بگیرید. لکه را هر یک یا چند دقیقه بمالید، این کار به کمرنگ
          شدن لکه‌های سمج‌تر کمک خواهد کرد. برای پاک کردن لکه‌های تازه‌تر این
          کار را ۲ تا ۳ بار تکرار کنید. یک روش دیگر این است که مقداری پودر
          لباس‌شویی را با سرکه سفید و آب به مقدار مساوی مخلوط‌ کنید. همه این
          مواد را مخلوط کنید تا به صورت خمیری درآید. این ترکیب را روی قسمتی از
          لباس که معمولاً در معرض دید نیست امتحان کنید تا مطمئن شوید که رنگ
          پارچه را نمی‌برد. اگر رنگ پارچه را از بین برد مستقیم به مرحله‌ بعدی
          بروید. برای پاک کردن لکه از روی لباس سفید خمیر را روی لکه بمالید. با
          استفاده از یک مسواک، برس ناخن یا قلم‌مو هر دو سمت لکه را به آرامی
          بسابید و سپس پارچه را کاملاً آب بکشید.
        </p>

        <h1>۱۰- لکه چای</h1>

        <p>
          برای پاک کردن لکه چای بهتر است بلافاصله ابتدا لباس را با آب سرد
          بشورید. بهتر است لباس را زیر و رو کنید و این کار را از قسمت زیر لباس
          انجام دهید نه قسمت روی لباس. این کار باعث می‌شود لکه به جای نفوذ در
          تار و پود لباس به بیرون رانده شود. در مرحله بعد مقداری مایع لباسشویی
          روی لکه بریزید و آن را بسابید. حداقل ۵ دقیقه صبر کنید و سپس به مدت ۳۰
          دقیقه لباس را در آب سرد بخیسانید و بعد آبکشی کنید. یک راه دیگر برای
          پاک کردن لکه چای از روی لباس استفاده از جوش ‌شیرین است. بهترین کار این
          است که مقداری جوش ‌شیرین روی لکه بریزید و بعد از پاک کردن جوش ‌شیرین
          لباس را با ماشین لباسشویی بشویید.
        </p>

        <h1>۱۱- از بین بردن لکه لاک از روی لباس</h1>

        <p>
          گاهی ممکن است مقداری لاک روی لباس بریزد و باعث لکه‌های بدی روی لباس‌ها
          شود. برای رفع لاک از روی لباس باید از استون استفاده کنیم که حلال لاک
          است. قبل از اینکه استون را روی لکه بریزید سعی کنید با قسمت دیگری از
          لباس که در دید نیست استون را امتحان کنید تا باعث رفتن و حل شدن رنگ
          لباس شما نشود. در این صورت می‌توانید با یک گوش‌پاک‌کن و کمی استون لاک
          را به‌آرامی از روی لباس پاک کنید.
        </p>
      </MasterItem>
    );
  }
}

export default RoutinItem3;
