import React, { Component } from "react";

import { Link } from "react-router-dom";

import styles from "./UseFules.module.css";
import { BsPencil } from "react-icons/bs";
import { Fade } from "react-reveal";

import img1 from "./../../../../images/New folder/useful/1.jpg";
import img2 from "./../../../../images/New folder/useful/2.jpg";
import img3 from "./../../../../images/New folder/useful/3.jpg";
import img4 from "./../../../../images/New folder/useful/4.jpg";
import img5 from "./../../../../images/New folder/useful/5.jpg";

class UseFules extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0 });
  }
  state = {
    useFules: [
      {
        id: "use1",
        newsName: "8 ضرر مواد آرایشی",
        newsImage: img1,
        newsTextContent: "انواع ضرر های مواد آرایشی",
        writeIn: "",
        writeBy: "",
        routeToNavigate: "/usefuls/1",
      },
      {
        id: "use2",
        newsName: "چرا آب و روغن مخلوط نمیشوند ؟",
        newsImage: img2,
        newsTextContent: "علت چیست ؟",
        writeIn: "",
        writeBy: "",
        routeToNavigate: "/usefuls/2",
      },
      {
        id: "use3",
        newsName: "مناسب ترین کاغذ چیست ؟",
        newsImage: img3,
        newsTextContent: "بهترین کاغذ برای چاپ چیست ؟",
        writeIn: "",
        writeBy: "",
        routeToNavigate: "/usefuls/3",
      },
      {
        id: "use4",
        newsName: "علت چروک شدن لباس چیست ؟",
        newsImage: img4,
        newsTextContent: "چرا لباسم کسر آب میکنه ؟",
        writeIn: "",
        writeBy: " ",
        routeToNavigate: "/usefuls/4",
      },
      {
        id: "use5",
        newsName: "آشنایی با انواع گاز ها",
        newsImage: img5,
        newsTextContent: "در اینجا با برخی از انواع گاز آشنا میشویم",
        writeIn: "",
        writeBy: " ",
        routeToNavigate: "/usefuls/5",
      },
    ],
  };

  render() {
    return (
      <Fade left>
        <div className={styles.computerPage} ref={this.ComputerPageRef}>
          <div className={styles.newsItemsMasterContainer}>
            {this.state.useFules.reverse().map((item) => {
              return (
                <Link
                  to={`${item.routeToNavigate}`}
                  className={styles.computerNewsItem}
                  key={item.id}
                >
                  <div
                    className={styles.picContainer}
                    style={{ backgroundImage: `url("${item.newsImage}")` }}
                  ></div>
                  <div className={styles.absoluteItem}></div>

                  <div className={styles.contentContainer}>
                    <p className={styles.newsName}>{item.newsName}</p>
                    <p className={styles.newsName}>{item.newsTextContent}</p>
                    <p className={styles.writenBy}>
                      <BsPencil />
                      زینب فراهانی
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </Fade>
    );
  }
}

export default UseFules;
