//React
import React, { Component } from "react";
//React
//Images
import { GoCalendar } from "react-icons/go";
import { BsPencil } from "react-icons/bs";
//Images
//CSS
import styles from "./NewestContents.module.css";
//CSS
//Modules

import img1 from "./../../images/New folder/useful/1.jpg";
import img2 from "./../../images/New folder/useful/2.jpg";
import img3 from "./../../images/New folder/useful/3.jpg";
import img4 from "./../../images/New folder/useful/4.jpg";
import img5 from "./../../images/New folder/useful/5.jpg";

import { Link } from "react-router-dom";
//Modules
class NewestContents extends Component {
  constructor() {
    super();
    this.rightBigItem = React.createRef();
  }
  // Inja Jadid Tarin Khabar Ha ro Minvisim !
  // Format : [ {} ]
  state = {
    news: [
      {
        id: "use1",
        name: "8 ضرر مواد آرایشی",
        image: img1,
        newsTextContent: "انواع ضرر های مواد آرایشی",
        writeIn: "",
        writeBy: "زینب فراهانی",
        routeToNavigate: "/usefuls/1",
        about: "",
      },
      {
        id: "use2",
        name: "چرا آب و روغن مخلوط نمیشوند ؟",
        image: img2,
        newsTextContent: "علت چیست ؟",
        writeIn: "",
        writeBy: "زینب فراهانی",
        routeToNavigate: "/usefuls/2",
        about: "",
      },
      {
        id: "use3",
        name: "مناسب ترین کاغذ چیست ؟",
        image: img3,
        newsTextContent: "بهترین کاغذ برای چاپ چیست ؟",
        writeIn: "",
        writeBy: "زینب فراهانی",
        routeToNavigate: "/usefuls/3",
        about: "",
      },
      {
        id: "use4",
        name: "علت چروک شدن لباس چیست ؟",
        image: img4,
        newsTextContent: "چرا لباسم کسر آب میکنه ؟",
        writeIn: "",
        writeBy: "زینب فراهانی",
        routeToNavigate: "/usefuls/4",
        about: "",
      },
      {
        id: "use5",
        name: "آشنایی با انواع گاز ها",
        image: img5,
        newsTextContent: "در اینجا با برخی از انواع گاز آشنا میشویم",
        writeIn: "",
        writeBy: "زینب فراهانی",
        routeToNavigate: "/usefuls/5",
        about: "",
      },
    ],
  };
  render() {
    return (
      <div className={styles.newestContents}>
        <Link
          ref={this.rightBigItem}
          to={this.state.news[4].routeToNavigate}
          className={styles.right}
          id={styles.rightBigItem}
          style={{ backgroundImage: `url("${this.state.news[4].image}")` }}
        >
          <div className={styles.absoluteOverly}></div>
          {/* <div className={styles.about}>{this.state.news[4].about}</div> */}
          <div className={styles.name}>{this.state.news[4].name}</div>
          <div className={styles.dateAndWrite}>
            {this.state.news[4].writeIn}

            <BsPencil />
            {this.state.news[4].writeBy}
          </div>
        </Link>
        {/*  */}
        {/*  */}
        <div className={styles.left}>
          <div className={styles.top}>
            <Link
              id={styles.topLeft}
              to={this.state.news[0].routeToNavigate}
              className={styles.left}
              style={{ backgroundImage: `url("${this.state.news[0].image}")` }}
            >
              <div className={styles.absoluteOverly}></div>
              <div className={styles.about}>{this.state.news[0].about}</div>
              <div className={styles.name}>{this.state.news[0].name}</div>
              <div className={styles.dateAndWrite}>
                {this.state.news[0].writeIn}

                <BsPencil />
                {this.state.news[0].writeBy}
              </div>
            </Link>
            <Link
              to={this.state.news[1].routeToNavigate}
              className={styles.right}
              id={styles.topRight}
              style={{ backgroundImage: `url("${this.state.news[1].image}")` }}
            >
              <div className={styles.absoluteOverly}></div>
              <div className={styles.about}>{this.state.news[1].about}</div>
              <div className={styles.name}>{this.state.news[1].name}</div>
              <div className={styles.dateAndWrite}>
                {this.state.news[1].writeIn}

                <BsPencil />
                {this.state.news[1].writeBy}
              </div>
            </Link>
          </div>
          <div className={styles.bottom}>
            <Link
              to={this.state.news[2].routeToNavigate}
              className={styles.left}
              id={styles.bottomLeft}
              style={{ backgroundImage: `url("${this.state.news[2].image}")` }}
            >
              <div className={styles.absoluteOverly}></div>
              <div className={styles.about}>{this.state.news[2].about}</div>
              <div className={styles.name}>{this.state.news[2].name}</div>
              <div className={styles.dateAndWrite}>
                {this.state.news[2].writeIn}

                <BsPencil />
                {this.state.news[2].writeBy}
              </div>
            </Link>
            <Link
              to={this.state.news[3].routeToNavigate}
              className={styles.right}
              id={styles.bottomRight}
              style={{ backgroundImage: `url("${this.state.news[3].image}")` }}
            >
              <div className={styles.absoluteOverly}></div>
              <div className={styles.about}>{this.state.news[3].about}</div>
              <div className={styles.name}>{this.state.news[3].name}</div>
              <div className={styles.dateAndWrite}>
                {this.state.news[3].writeIn}
                <BsPencil />
                {this.state.news[3].writeBy}
              </div>
            </Link>
          </div>
        </div>
        {/*  */}
        {/*  */}
      </div>
    );
  }
}

export default NewestContents;
