import React, { Component } from "react";

import styles from "./../MASTER__STYLE.module.css";

import { Fade } from "react-reveal";
class MasterItem extends Component {
  state = {};

  componentDidMount() {
    window.scrollTo({ top: 0 });
  }

  render() {
    return (
      <Fade bottom>
        <div className={styles.newsContent}>{this.props.children}</div>
      </Fade>
    );
  }
}

export default MasterItem;
