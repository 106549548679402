import React, { Component } from "react";
import MasterItem from "../../MasterItem/MasterItem";

class RoutinItem4 extends Component {
  state = {};
  render() {
    return (
      <MasterItem>
        <h1>از بین بردن بوی بد خانه با چند ترفند ساده</h1>

        <p>
          خانه از قسمت های مختلف با کاربری های متفاوتی ساخته شده که هر کدام می
          توانند منشا ایجاد بوی بد در خانه باشند. بوی نامطبوع حاصل از فاضلاب،
          سرویس های دستشویی و حمام، دود و سوختگی غذا برخی از رایج ترین بوهایی
          هستند که فضای خانه را آزار دهنده می کنند. برای رفع بوی بد خانه چه
          راهکاری را اندیشیده اید؟ بدیهی است قبل از هر اقدامی باید منبع بوی
          ناخوشایند را شناسایی کرد و در رفع بوی بد خانه کوشید.
        </p>

        <h1>از بین بردن بوی بد فاضلاب</h1>

        <p>
          بوی فاضلاب همیشه نامطبوع و آزار دهنده است حال تصور کنید که چنین بویی
          در فضای بسته و محدود آپارتمان به وجود آید. به طور حتم زندگی در چنین
          محیطی امکان پذیر نیست. فاضلاب در اکثر آشپزخانه ها منبع بوی ناخوشایند
          است. علاوه بر آن این بو می تواند حاوی گاز فاضلاب یا سولفید هیدروژن
          باشد که یک گاز سمی و بد بوست. برای رفع بوی بد خانه و رهایی از بوی
          نامطبوع فاضلاب بهترین اقدام استفاده از ترکیب سرکه سفید و جوش شیرین
          است. این ماده بوی ناخوشایند فاضلاب را از بین خواهد برد. از این ترکیب
          در سینک ظرفشویی نیز بریزید تا بوی ناخوشایند بقایای مواد غذایی را از
          بین ببرد. یک کتری آب جوش، یک لیوان جوش شیرین و یک لیوان نمک نیز یک
          مخلوط جادویی برای از بین بردن بوی نامطبوع فاضلاب است. استفاده از ترکیب
          اول یا دوم باید به فواصل زمانی مشخصی صورت گیرد تا همواره از بوی بد
          خانه و فاضلاب ها رهایی یابید.
        </p>

        <h1>رهایی از بوی نامطبوع در آشپزخانه</h1>

        <p>
          سینک و فاضلاب را با ترکیب سرکه و جوش شیرین تمیز کرده اید. کابینت ها و
          میز آشپزخانه هم که عاری از باقی مانده مواد غذایی و فاسد شدنی است ولی
          باز هم بوی ناخوشایندی از آشپزخانه به مشام می رسد. علت این بوی
          ناخوشایند چیست؟ دقت کنید که کیسه زباله می تواند منشا اکثر بوهای
          نامطبوع باشد. علاوه بر آن ماشین لباسشویی، یخچال، اجاق گاز و همه لوازم
          آشپزخانه نیز می توانند منبع بوهای آزار دهنده باشند. برای رفع بوی بد
          خانه لازم است تا تک تک لوازم آشپزخانه را تمیز کرده و از نظافت آن
          اطمینان داشته باشید.
        </p>

        <h1>رفع بوی بد خانه در سرویس های بهداشتی</h1>

        <p>
          سرویس های بهداشتی یکی از آلوده ترین مکان های هر منزلی هستند که باید در
          پاکیزگی و بهداشت این مناطق نهایت دقت به کار برده شود. حمام و دستشویی
          نیز دارای فاضلاب هستند که می توان از ترکیب سرکه و جوش شیرین برای رفع
          بوی بد فاضلاب حمام نیز استفاده کرد. علاوه بر بوی فاضلاب، خود حمام و
          دستشویی نیز بوی ناخوشایندی دارند که آزار دهنده است. برای رفع بوی بد
          سرویس بهداشتی از دستگاه بخور خانگی استفاده کنید. کمی اسانس گیاهان معطر
          با رایحه مورد علاقه خود را در دستگاه بخور بریزید تا همواره بوی خوش و
          مطبوعی را داشته باشید. حتی می توان زمین شوی را به اسانس خوشبو آغشته
          کرد.
        </p>

        <h1>از بین بردن بوی غذا و سوختگی</h1>

        <p>
          وجود هود و پنجره در آشپزخانه نعمت بزرگی است که اکثر بوها را از بین می
          برد. هود را روشن کرده و یا پنجره را باز کنید به همین سادگی. اگر هنوز
          بوی سوختگی از بین نرفته کافی است یک حوله را خیس کرده و در محیط
          آشپزخانه تکان دهید. استفاده ازمقداری آب و عدد چوب دارچین که روی اجاق
          گاز می جوشد نیز در رفع بوی بد خانه و از بین بردن بوی آزار دهنده سوختگی
          غذا بسیار موثر است.
        </p>

        <p>
          جوشاندن یک ظرف آب به همراه چند برش لیمو ترش نیز همین خاصیت را دارد و
          بوی خوش لیمو ترش هم در فضا می پیچد.
        </p>

        <h1>از بین بردن بوی رنگ در فضای آپارتمان</h1>

        <p>
          نقاشی و رنگ کردن دیوارها همیشه هم بوی ناخوشایندی ندارد با توجه به نوع
          رنگ استفاده شده میزان بوی رنگ نیز متفاوت است. البته بهترین راه باز
          کردن پنجره های آپارتمان است ولی اگر امکان این کار را ندارید می توانید
          در یک ظرف مناسب مقداری آب سرد ریخته و یک قاشق بزرگ آمونیاک را به آن
          اضافه کنید این مایع در جذب بوی رنگ موثر است. یک پیاز بزرگ حلقه شده در
          آب هم همین اثر را دارد.
        </p>

        <h1>رفع بوی سیگار از فضای خانه</h1>

        <p>
          به طور کلی سیگار کشیدن در محیط های بسته و فضای آپارتمان کار صحیحی نیست
          به خصوص اگر کودکی نیز در منزل زندگی کند. در این صورت بهتر است از سیگار
          کشیدن در منزل خودداری شود. از آن گذشته اگر فرد سیگاری در خانه باشد
          متوجه می شوید که بوی سیگار به راحتی از بین نخواهد رفت. باز کردن پنجره
          ها، روش کردن هود، روشن کردن شمع، کاسه ای سرکه سفید در گوشه ای از منزل
          از راهکارهایی است که می تواند بوی سیگار را کمتر کند.
        </p>
      </MasterItem>
    );
  }
}

export default RoutinItem4;
