import React from "react";
import MasterItem from "../../MasterItem/MasterItem";

const UseFulItem2 = () => {
  return (
    <MasterItem>
      <h1>چرا روغن و آب با یک دیگر مخلوط نمی‌شوند</h1>

      <p>
        حتماً دیده‌اید که روغن بر روی آب شناور می ماند. فکر می‌کنید به چه علت
        آنها هرگز با یک دیگر مخلوط نمی‌شوند؟ مطلب زیر را بخوانید تا علت را متوجه
        شوید .
      </p>

      <p>
        هنگامی که قطرات روغن را می‌بینیم که بر روی آب شناورند، گاهی به نظرمان
        روغن مثل یک مایع کله شق می‌ماند که از ترکیب با آب امتناع می‌ورزد. در
        مواقع دیگر، فکر می‌کنیم که شاید تقصیر آب است که نمی‌گذارد روغن با او
        ترکیب شود. شاید هم، هم روغن و هم آب نمی‌خواهند با یک دیگر ترکیب شوند و
        یا علم این اجازه را نمی دهد! بیایید به آن چه که واقعاً آنها را از ترکیب
        با یک دیگر باز می‌دارد، نگاهی بیاندازیم. آب و روغن هر دو مایعند، اما
        ترکیبات شیمیایی آنها با یک دیگر بسیار تفاوت دارد. از نظر شیمیایی، تمام
        انواع روغن‌ها، زنجیره طولانی از اتمهای کربنند که اتمهای هیدروژن به آنها
        متصل هستند. روغن از هیدرو کربن‌های بلند غیر قطبی تشکیل شده است، بدان
        معنا که نیروی جاذبه ضعیفی را تحمل می‌کنند. از سوی دیگر، مولکولهای آب دو
        قطبیند، به این معنی که انتهای یکی از آنها بار مثبت و دیگری بار منفی
        دارد. هم چنین، مولکول‌های روغن بسیار بزرگتر از مولکول‌های آب می‌باشند.
        مخلوطی از هر دو مایع طبیعت مشابه مولکولهای آنها را نشان می‌دهد. تفاوت در
        ساختار مولکولی آب و روغن یکی از دلایل اصلی است که چرا آنها با یک دیگر
        ترکیب نمی‌شوند. آنها نمونه‌ مشهوری از مایعات غیر قابل ترکیبند. کشش سطحی
        یکی دیگر ازعوامل مهمی است که به خاطر آن، آب و روغن با یک دیگر ترکیب
        نمی‌شوند. هنگامی که مولکولهای آب گرد هم می‌آیند، شبکه‌ای از پیوندهای
        هیدروژنی را تشکیل می‌دهند که منجر به کشش سطحی بسیار بالا می‌شود. هم آب و
        هم روغن کشش سطحی بالایی دارند که به این دلیل، چسبندگی بین آنها ضعیف بوده
        و آنها با یک دیگر ترکیب نمی‌شوند. به چه علت قطرات روغن به صورت دایره‌ای
        بر روی آب شناورند؟ این به دلیل کشش سطحی است. مولکولهای روغن به یک دیگر
        می‌چسبند تا لایه‌ای الاستیک با حد اقل سطح ممکن را تشکیل دهند. به دلیل
        این فعل و انفعالات در مولکولهای روغن، آنها قطرات دایره‌ای شکلی را بر روی
        سطح آب تشکیل می‌دهند. برای این که دو مایع با یک دیگر ترکیب شوند،
        پیوندهای شیمیایی که مولکولها را به یک دیگر متصل می کنند بایستی شکسته شده
        و پیوندهای جدیدی تشکیل شوند. پیوند بین مولکولهای آب بسیار قوی است، در
        حالی که پیوندهای میان ملکولهای روغن ضعیفند. از این رو، مقدار زیادی انرژی
        نیاز است تا مولکولهای آب و روغن را به یک دیگر متصل نماید. این توضیحی است
        که چرا روغن با آب ترکیب نمی‌شود. مولکولهایی که حامل بار می‌باشند را
        هیدرو فیلیک یا آب دوست گویند، در حالی که مولکولهای بدون بار را آب گریز
        (هیدرو فوبیک) گویند، به این معنا که آنها فاقد تمایل و وابستگی به آب
        هستند. به دلیل این که مولکولهای روغن (که زنجیره طولانی از هیدرو کربن می
        باشند) حامل هیچ باری نیستند، نمی‌توانند با آب پیوند برقرار کنند. در
        نتیجه، روغن و آب با یک دیگر ترکیب نمی‌شوند. اما چرا روغن بر روی آب شناور
        بوده و به زیر آن فرو نمی‌رود؟ روغن بر روی آب شناور است زیرا چگالی آن از
        چگالی آب کمتر است. با این حال، می‌توان با اضافه کردن امولسیفایر (ماده
        امولسیون کننده) به آنها، این دو را وادار کرد با یک دیگر ترکیب شوند.
        امولسیفایر ماده‌ای است که استفاده از آن باعث ترکیب روغن و آب می‌شود. نمک
        را به روغن اضافه کنید تا ببینید که چگونه روغن به زیر آب فرو می‌رود.
        اضافه کردن مواد شوینده نیز همان اثر را بر روغن دارد و روغن به آسانی با
        آب مخلوط می‌شود. مولکولهای مواد شوینده، «شکاف» بین مولکولهای آب و روغن
        را پر کرده و اجازه می‌دهند آنها با یک دیگر مخلوط شوند. افزودن امولسیفایر
        به روغن و آب، ترکیبی با ثبات ایجاد می‌کند. چیزی که از آن مطمئنیم این است
        که، هر چند آب و روغن در شرایط عادی با یک دیگر ترکیب نمی‌شوند، اما آب به
        اندازه کافی بخشنده است که اجازه دهد روغن در بالا شناور باشد!
      </p>
    </MasterItem>
  );
};

export default UseFulItem2;
