import React from "react";
import MasterItem from "../../MasterItem/MasterItem";

const UsefulItem5 = () => {
  return (
    <MasterItem>
      <h1>انواع گازها</h1>

      <p>
        گازهای صنعتی به دسته‌ای از ترکیبات شیمیایی گازی شکل گفته می‌شود که به
        صورت انبوه تولید شده و در صنایع مختلف از جمله نفت و گاز، پتروشیمی،
        نیروگاه‌ها، صنایع شیمیایی و دارویی، صنایع فولاد، صنایع غذایی و صنایع
        هسته‌ای، کاربرد دارد. بخشی از این گازها مانند اکسیژن و نیتروژن، به وفور
        در هوای تنفسی وجود داشته و در نتیجه از طریق جداسازی از هوا تولید می‌شوند
        بخش دیگری از انواع گازها از گاز طبیعی یا خروجی‌های گازی چاه‌های نفتی
        تهیه می‌شوند و برخی دیگر با فرآوری در واحدهای ویژه تولید می‌ش
      </p>

      <h1>انواع گازها</h1>
      <p>
        گازها یکی از 4 حالت ماده اصلی (جامد، مایع، گاز، پلاسما) در جو و زمین
        هستند چیزی که گاز ها را از دیگر حالت های ماده جدا می کند جنبش و فاصله
        زیاد بین ذرات آن است.
      </p>

      <h1>گاز نئون</h1>
      <p>این گاز در انواع گازها ازیک اتم ساخته شده است</p>

      <h1>گاز نیتروژن (N2)</h1>
      <p>
        نیتروژن یا ازت یکی از عنصرهای شیمیایی در جدول تناوبی است که نماد آن N و
        عدد اتمی آن ۷ است نیتروژن معمولاً به صورت یک گاز، غیر فلز، دو اتمی بی
        اثر، بی‌رنگ، بی‌مزه و بی‌بو است که ۷۸٪ جو زمین را دربر گرفته و عنصر اصلی
        در بافت‌های زنده است.
      </p>

      <h1>گاز اکسیژن (O2)</h1>
      <p>
        اکسیژن یکی از عناصر شیمیایی در جدول تناوبی است که نماد آن O و عدد اتمی
        آن ۸ است یک عنصر زیستی بوده و همه جا چه در زمین و چه در کل جهان هستی
        یافت می‌شود. مولکول اکسیژن (O۲)در زمین از نظر ترمودینامیکی ناپایدار است
        .
      </p>

      <h1>گاز آرگون (Ar)</h1>
      <p>
        آرگون با علامت اختصاری Ar در جدول عناصر دارای شماره اتمی ۱۸ است. این
        عنصر در گروه ۱۸ (گازهای نجیب) قرار دارد. از این رو، هیچ ترکیبی از آن به
        صورت طبیعی وجود ندارد. گاز آرگون در هواکره(اتمسفر) زمین تا حدود ٪۱ موجود
        است.
      </p>

      <h1>گاز هلیم (He)</h1>
      <p>
        هِلیوم یا هلیُم (Helium) با نشان شیمیایی He یک عنصر شیمیایی با عدد اتمی
        ۲ و وزن اتمی ۴٫۰۰۲۶۰۲ است . این عنصر، بی‌بو، بی‌رنگ، بی‌مزه، غیرسمّی، از
        دیدگاه شیمیایی بی‌اثر و تک اتمی است که در جدول تناوبی در بالای گروه
        گازهای نجیب جا دارد. دمای ذوب و جوش این ماده در میان دیگر عنصرها بسیار
        پایین است .
      </p>

      <h1>گاز کریپتون (Kr)</h1>
      <p>
        کریپتون (Krypton) عنصر شیمیایی با نشانهٔ Kr، عدد اتمی ۳۶، جرم اتمی
        ۸۳٫۸۰، شش ایزوتوپ پایدار و چند ایزوتوپ ساختگی پرتوزا دارد. از گروه
        گازهای نجیب و تناوب چهارم جدول تناوبی، گازی است بی‌رنگ، بی‌بو و ۱٫۱۸
        مرتبه از هوا سنگین تر است. نام آن از واژهٔ کریپتوس (kryptos به معنای
        مخفی) گرفته شده‌است؛ زیرا مدت زیادی کشف نشده باقی مانده بود.
      </p>

      <h1>گاز زنون (عنصر) (Xe)</h1>
      <p>
        زنون عنصری با عدد اتمی ۵۴؛ در گروه گازهای بی اثر یا نجیب و در دوره پنجم
        جدول تناوبی جای دارد. جرم اتمی ۱۳۱٫۳۰؛ ظرفیتها ۲٬۴٬۶و ۸. دارای نه
        ایزوتوپ پایدار است.
      </p>

      <h1>گاز رادون (Rn)</h1>
      <p>
        رادون یکی از عناصر شیمیایی جدول تناوبی است که نماد آن Rn و عدد اتمی آن
        ۸۶ است. این عنصر از گازهای نجیب و پرتوزا است که از فروپاشی رادیم به وجود
        می‌آید. رادون یکی از سنگین‌ترین گازها بوده و برای سلامتی مضر است.
        پایدارترین ایزوتوپ آن 222Rn است که نیمه عمرش برابر با ۳٫۸ روز می‌باشد.
      </p>

      <h1>گاز کلر (Cl2) (بخار)</h1>
      <p>
        خصوصیات فیزیکی: در دما و فشار استاندارد دو اتم کلر تشکیل مولکول ۲ اتمی
        کلر را می‌دهند Cl2 گاز سبز، زرد رنگی است . که بوی قوی متمایزی دارد (بوی
        سفیدکننده).
      </p>

      <h1>گاز فلوئور (F2)</h1>
      <p>
        فلوئور خالص (گاز) زردرنگ و فرسایشگری است که عامل اکسیدکننده‌ای قوی
        می‌باشد. این گاز از تمامی عناصر الکترونگاتیوتر (الکترون دوست) و واکنش
        پذیرتر است . با بیشتر عناصر دیگر از جمله گازهای بی‌اثر، زنون و رادون
        تولید ترکیب ایجاد می‌کند.
      </p>
    </MasterItem>
  );
};

export default UsefulItem5;
