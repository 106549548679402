import React from "react";
import MasterItem from "../../MasterItem/MasterItem";

const UseFulItem1 = () => {
  return (
    <MasterItem>
      <p>8 ماده مضر در لوازم آرایش که باید از آن‌ها دوری کنید</p>
      <p>
        هر خانمی از زیباتر شدن لذت می‌برد و دوست دارد همیشه بهترین ظاهر را داشته
        باشد. اما به چه قیمتی؟ حاضر هستید از هر گونه لوازم آرایشی استفاده کنید؟
        اگر مراقب انتخاب لوازم آرایش نباشید و از خود محافظت نکنید، به جای اینکه
        باعث زیباتر شدن و بهتر شدن وضعیت ظاهری خود شده باشید، به بدن خود آسیب
        می‌زنید. شاید استفاده از هر وسیله آرایشی بتواند در کوتاه‌مدت ظاهری
        زیباتر به شما بدهد، ولی اگر مواد مضر در لوازم آرایش شما وجود داشته باشد،
        زیبایی شما بلندمدت نخواهد بود و بیشتر مضرات لوازم آرایش نسیب شما خواهد
        شد. لوازم آرایشی که با استفاده از مواد شیمیایی ساخته می‌شوند، باید از
        مصرف دور بمانند خصوصاً اگر حاوی یک یا چند ماده شیمیایی خطرناکی باشند که
        به نام دوجین کثیف یا dirty dozen معروف‌اند؛ در واقع این مواد شیمیایی از
        خطرناک‌ترین ترکیباتی هستند که در بسیاری از محصولات آرایشی پیدا می‌شوند.
        این محصولات شامل لوازم آرایشی، محصولات مربوط به مو، لوسیون، صابون،
        محصولات مربوط به ناخن، عطر و محصولات مراقبتی پوست هستند. مشکلی که با
        مصرف این محصولات خطرناک ایجاد می‌شود این است که سموم خطرناکی که در
        ترکیباتشان وجود دارد، وارد پوست بدن می‌شود. این محصولات در طول روز روی
        پوست شما قرار دارد و شما به آنها اجازه می‌دهید که به جریان خون نفوذ کند
        و باعث بروز مشکل شود. حتی محصولاتی مانند شامپو، رنگ مو، نرم‌کننده و
        صابون هم که شسته می‌شوند، هم در طول حمام داغ که منافذ پوست باز می‌شوند،
        به درون این منافذ نفوذ می‌کنند. پس اگر شما هم از محصولات مختلف آرایشی و
        بهداشتی استفاده می‌کنید، یک لحظه فکر کنید که اگر ترکیبات شیمیایی داشته
        باشند تا چه اندازه مواد خطرناک را وارد بدن خود کرده‌اید.
      </p>
      توجه به مواد استفاده شده در محصولات مختلف پوست و مو بسیار مهم است، حداقل
      در این صورت می‌توانید از مضرات لوازم آرایش که ممکن است شما را به مخاطره
      بیندازد، جلوگیری کنید. مطالعاتی که محققان آمریکایی انجام داده‌اند، نشان
      می‌دهد که حدود ۸۲ هزار ماده مختلف در محصولات آرایشی و بهداشتی مورد استفاده
      قرار می‌گیرد. یک هشتم این ترکیبات حاوی حشره‌کش‌ها، مواد شیمیایی صنعتی،
      اختلال دهنده هورمونی، سموم مؤثر بر دستگاه تولید مثل، مواد سرطان‌زا،
      پلاستیسایزرها (برای صافی پوست به کار می‌روند)، رقیق‌کننده‌ها و سورفاکتانت
      یا مواد فعال سطحی هستند. برخی از این مواد با سرطان، تولد نوزاد ناقص و
      نازایی در ارتباط هستند. این ۱۴ ماده سمی که دلیل بخش عمده مضرات لوازم آرایش
      هستند، در ۸۰ درصد محصولات کشور کانادا یافت شده است.
      <h1>1- تری کلوسان</h1>
      <p>
        این ماده شیمیایی معمولاً در دئودورانت‌ها، صابون‌های ضد باکتری و خمیر
        دندان یافت می‌شود. هدفش این است که از کپک و قارچ جلوگیری کند. این ماده
        بر سیستم‌های هورمون بدن، به خصوص تیروئید که مسئول تنظیم متابولیسم بدن
        است، تأثیر می‌گذارد. این ماده شیمیایی همچنین می‌تواند رشد طبیعی سینه‌ها
        را متوقف کند.
      </p>
      <h1>2- فتالات</h1>
      <p>
        این ماده شیمیایی به عنوان ماده‌ای که سیستم غدد درون‌ریز را مختل می‌کند،
        شناخته شده است. این ماده معمولاً در رایحه‌های مصنوعی، مواد براق‌کننده و
        انواع لوازم آرایشی دیگر یافت می‌شود. عوامل خطرناک مرتبط با فتالات
        عبارت‌اند از بلوغ زودرس دختران، سرطان پستان در زنان مسن و تضعیف استروژن
        در سیستم‌های کشت سلولی.
      </p>
      <h1>3 - پارابن</h1>
      <p>
        احتمالاً با این ماده آشنا هستید، به ویژه اگر از طرفداران محصولات طبیعی
        باشید و به دوری از مضرات لوازم آرایش اهمیت بدهید. این ماده شیمیایی
        معمولاً در پماد، لوسیون، کرم و دئودورانت یافت می‌شود. پارابن به راحتی
        توسط پوست جذب می‌شوند و با تومور پستانی مرتبط است.
      </p>
      <h1>4 - دی‌اکسان</h1>
      <p>
        این ماده به نام پلی اتیلن گلیکول هم شناخته می‌شود. این ماده به سختی در
        برچسب‌های لوازم آرایشی یافت می‌شود، اما در واقع در بسیاری از آنها وجود
        دارد. این ماده از نفت مشتق می‌شود و در محصولاتی مانند محصولات شوینده
        حمام کودکان، شامپو، مواد شوینده بدن و سایر محصولاتی که کف تولید می‌کنند،
        استفاده می‌شود. این ماده توسط آژانس بین‌المللی تحقیقات سرطان (IARC) و
        برنامه ملی سم‌شناسی (NTP) به عنوان ماده سرطان‌زا شناخته شده است.
      </p>
      <h1>5 - اتیلن اکسید</h1>
      <p>
        در صنعت پزشکی، این ماده را برای استریل کردن تجهیزات جراحی استفاده
        می‌کنند. در صنعت تولید محصولات مراقبت شخصی، از اتیلن اکسید در محصولات
        شوینده بدن و شامپو به عنوان یک عامل کف کننده استفاده می‌شود. این ترکیب
        به عنوان ماده سرطان‌زا شناخته شده و با سرطان پستان مرتبط است.
      </p>
      <h1>6 - بوتیلیتد هیدروکسی نازول BHA و بوتیلیتد روزتیولین BHT</h1>
      <p>
        این مواد در لوازم آرایش و مرطوب کننده‌ها به عنوان نگهدارنده استفاده
        می‌شود. احتمال می‌رود که این مواد می‌توانند باعث اختلال در سیستم غدد
        درون‌ریز و احتمالاً بروز سرطان شود. با این حال، اخیراً اثبات شده است که
        برای ماهی و دیگر انواع حیوانات مضر هستند.
      </p>
      <h1>7 - فرمالدهید</h1>
      <p>
        برخی از نگهدارنده‌های درون لوازم آرایشی به آهستگی فرمالدهید آزاد می‌کنند
        که با سرطان مرتبط است. آنچه لازم است در مورد استفاده از این ماده بر روی
        برچسب‌های محصولات آرایشی به دنبالش بگردید، تحت عنوان این نام‌ها خواهند
        بود: دی ام دی ام هیدانتوین، ایمیدازولیدینیل اوره، کوارترنیوم ۱۵ و
        متانامین.
      </p>
      <h1>8 - مواد معطر</h1>
      <p>
        این مواد همچنین به عنوان رایحه و عطر هم شناخته شده که در انواع لوازم
        آرایشی و بهداشتی استفاده می‌شود. حتی محصولاتی که به نام بی‌بو برچسب
        خورده‌اند هم ممکن است حاوی این ماده باشند. مواد معطر باعث افزایش آلرژی و
        آسم می‌شوند و با مشکلات عصبی و سرطان مرتبط هستند.
      </p>
    </MasterItem>
  );
};

export default UseFulItem1;
